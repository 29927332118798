@charset "UTF-8";
/**
 * woocommerce.scss
 * Governs the general look and feel of WooCommerce sections of stores using themes that do not
 * integrate with WooCommerce specifically.
 */
/**
 * Imports
 */
/**
 * Custom Properties
 */
/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
/**
 * Objects
 */
/*--------------------------------------------------------------
# Archive Top
--------------------------------------------------------------*/
.thebase-shop-top-row {
  padding-block-end: var(--global-md-spacing);
  display: flex;
  align-items: center; }

.thebase-product-toggle-container {
  display: flex; }

.thebase-product-toggle-container .thebase-toggle-shop-layout {
  padding: 0px 6px;
  color: var(--global-palette4);
  margin-inline-end: 0.5em;
  border: 1px solid var(--global-gray-400);
  display: flex;
  height: 34px;
  border-radius: 0;
  align-items: center;
  background: transparent;
  box-shadow: none; }

.thebase-product-toggle-container .thebase-toggle-shop-layout .thebase-svg-iconset {
  pointer-events: none; }

.thebase-product-toggle-container .thebase-toggle-shop-layout.toggle-active, .thebase-product-toggle-container .thebase-toggle-shop-layout:hover {
  color: var(--global-palette3);
  border-color: var(--global-palette5);
  background: transparent;
  box-shadow: none; }

.thebase-product-toggle-container .thebase-toggle-shop-layout .thebase-list-svg {
  top: -1px;
  position: relative; }

.thebase-woo-results-count {
  flex-grow: 1;
  margin-block-end: 0.5em; }

.thebase-woo-results-count p {
  margin: 0; }

@media screen and (min-width: 768px) {
  .thebase-shop-top-row {
    justify-content: flex-end; }
  .thebase-woo-results-count {
    margin-block-end: 0; } }

select.orderby {
  padding-block-end: 6px;
  padding-block-start: 5px;
  padding-inline-start: 6px;
  padding-inline-end: 25px;
  height: auto;
  background-color: transparent;
  cursor: pointer;
  appearance: none;
  background-size: auto 18px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==);
  border: 1px solid var(--global-gray-400) !important;
  background-position: right 6px top 50%; }

.woocommerce {
  /**
        * Product loops
        */ }

.woocommerce .products ul,
.woocommerce ul.products {
  column-gap: 0.075rem;
  row-gap: 0.075rem;
  margin: 0 0 1em;
  padding: 0;
  list-style: none outside;
  clear: both;
  margin: 0 -15px; }

.woocommerce .products ul li,
.woocommerce ul.products li {
  list-style: none outside;
  text-align: center; }

.woocommerce .products ul li .star-rating,
.woocommerce ul.products li .star-rating {
  margin: .6em 0; }

.woocommerce ul.products li.product .product-excerpt {
  display: none; }

.woocommerce ul.products li.product a.woocommerce-loop-image-link {
  position: relative;
  display: block; }

.woocommerce ul.products li.product .entry-content-wrap {
  margin: 0;
  padding-block: 20px 0;
  position: relative;
  border-radius: 0.25rem;
  transition: transform 0.3s cubic-bezier(0.17, 0.67, 0.35, 0.95);
  text-align: left; }

.woocommerce ul.products li.product h3,
.woocommerce ul.products li.product .woocommerce-loop-product__title,
.woocommerce ul.products li.product .woocommerce-loop-category__title {
  padding: 0 0 .3rem 0;
  margin: 0;
  font-size: 1em;
  font-weight: 500; }

.woocommerce ul.products li.product h3 a,
.woocommerce ul.products li.product .woocommerce-loop-product__title a,
.woocommerce ul.products li.product .woocommerce-loop-category__title a {
  transition: none; }

.woocommerce ul.products li.product a {
  text-decoration: none;
  color: inherit; }

.woocommerce ul.products li.product a img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0;
  box-shadow: none; }

.woocommerce ul.products li.product .woocommerce-placeholder {
  border: 1px solid #f2f2f2; }

.woocommerce ul.products li.product .button .thebase-spinner-svg,
.woocommerce ul.products li.product .button .thebase-check-svg {
  display: none; }

.woocommerce ul.products li.product .button.loading {
  opacity: 0.25; }

.woocommerce ul.products li.product .button.loading .thebase-arrow-right-alt-svg {
  display: none; }

.woocommerce ul.products li.product .button.loading .thebase-spinner-svg {
  display: block;
  animation: spin 2s linear infinite; }

.woocommerce ul.products li.product .button.loading::after {
  display: none; }

.woocommerce ul.products li.product .button.added.add_to_cart_button {
  font-size: 0;
  display: none; }

.woocommerce ul.products li.product .button.added .thebase-svg-iconset:last-child {
  font-size: 1rem; }

.woocommerce ul.products li.product .button.added .thebase-arrow-right-alt-svg {
  display: none; }

.woocommerce ul.products li.product .button.added .thebase-check-svg {
  display: block;
  font-size: 80%; }

.woocommerce ul.products li.product .price {
  color: inherit;
  display: block;
  font-weight: normal;
  padding-block: 0.3em; }

.woocommerce ul.products li.product .price del {
  color: inherit;
  opacity: 0.5;
  display: inline-block; }

.woocommerce ul.products li.product .price ins {
  background: none;
  display: inline-block; }

.woocommerce ul.products li.product .price .from {
  font-size: 0.67em;
  margin: -2px 0 0 0;
  text-transform: uppercase;
  color: var(--global-palette6); }

.woocommerce ul.products.woo-archive-btn-button .button,
.woocommerce ul.products.woo-archive-btn-button .wc-forward {
  color: var(--global-palette9);
  background: var(--global-palette2);
  text-align: center;
  transition: color 0.2s ease, background 0.2s ease, border 0.2s ease;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  font-size: 0;
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 50px; }

.woocommerce ul.products.woo-archive-btn-button .button:hover,
.woocommerce ul.products.woo-archive-btn-button .wc-forward:hover {
  color: var(--global-palette-btn-hover);
  background: var(--global-palette-btn-bg-hover); }

.woocommerce .products-list-view .woocommerce-loop-image-link {
  float: left; }

.woocommerce .products-list-view .entry-content-wra {
  overflow: hidden; }

.woocommerce ul.products:not(.woo-archive-btn-button) .button,
.woocommerce ul.products:not(.woo-archive-btn-button) .wc-forward {
  background: transparent;
  padding: 0.5em 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: inherit;
  font-size: 80%;
  border: 0;
  box-shadow: none;
  transition: color 0.2s ease;
  display: inline; }

.woocommerce nav.woocommerce-pagination {
  margin: var(--global-lg-spacing) auto;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 100%;
  position: relative;
  padding-block-start: 1em; }

.woocommerce nav.woocommerce-pagination ul {
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
  padding: 0; }

.woocommerce nav.woocommerce-pagination ul li .page-numbers {
  display: inline-block;
  margin-inline-end: 0.5em;
  min-width: 2.5em;
  line-height: 2.5em;
  height: 2.5em;
  text-align: center;
  text-decoration: none;
  border-color: var(--global-palette-btn-bg);
  background: var(--global-palette-btn-bg);
  color: var(--global-palette-btn); }

.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li a:focus {
  border-color: var(--global-palette-btn-bg-hover);
  background: var(--global-palette-btn-bg-hover);
  color: var(--global-palette-btn-hover); }

.woocommerce nav.woocommerce-pagination ul li span.current {
  border-color: var(--global-palette-btn-bg-hover);
  background: var(--global-palette-btn-bg-hover);
  color: var(--global-palette-btn-hover); }

.base-shop-top-row {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.archive.content-style-unboxed
.products.woo-archive-action-visible
li.product.content-bg.loop-entry
.content-bg:not(.loop-entry),
.post-type-archive.content-style-unboxed
.products.woo-archive-action-visible
li.product.content-bg.loop-entry
.content-bg:not(.loop-entry),
.single-product.content-style-unboxed
.products.woo-archive-action-visible
li.product.content-bg.loop-entry
.content-bg:not(.loop-entry) {
  background: transparent;
  margin-inline-start: 0;
  margin-inline-end: 0; }

.hot-product .archive-extra-button-wrap {
  display: none; }

ul.products .first li .container-inner::after {
  background: transparent; }

ul.products li .container-inner::after {
  content: "";
  position: absolute;
  width: 1px;
  background: #e5e5e5;
  height: 220px;
  top: 0;
  left: 0; }

.related ul.products .first .container-inner::after {
  background: transparent; }

.single-product .countbox.hastime {
  justify-content: flex-start; }

@media screen and (max-width: 640px) {
  .thebase-woo-results-count {
    margin-bottom: 0;
    text-align: center; }
  .thebase-shop-top-row {
    flex-direction: column;
    row-gap: 5px; }
  .woocommerce ul.products.products-list-view .entry-content-wrap {
    padding: 1em 0;
    float: left;
    width: 100%; } }

@media screen and (min-width: 640px) {
  .thebase-woo-toggle {
    align-items: center; }
  .archive.content-style-unboxed.woocommerce
ul.products.products-list-view
li.product.content-bg.loop-entry
.entry-content-wrap:not(.loop-entry) {
    padding: 0;
    padding-inline-start: 2rem;
    padding-block-start: 25px;
    overflow: hidden; }
  .woocommerce ul.products.products-list-view {
    grid-template-columns: 1fr; }
  .woocommerce ul.products.products-list-view li.product {
    display: grid;
    grid-template-columns: auto auto; }
  .woocommerce ul.products.products-list-view li.product .woocommerce-loop-product__title {
    font-size: 1.3em; }
  .woocommerce ul.products.products-list-view li.product .entry-content-wrap {
    padding: 1rem 1rem 1rem 1.5rem; }
  .woocommerce ul.products.products-list-view li.product .product-excerpt {
    display: block; }
  .woocommerce ul.products.products-list-view li.product .star-rating {
    float: none;
    margin-inline-start: 0; }
  .woocommerce ul.products.products-list-view li.product:hover .entry-content-wrap, .woocommerce ul.products.products-list-view li.product:focus-within .entry-content-wrap {
    transform: translateY(0); }
  .woocommerce ul.products.grid-lg-col-1.products-grid-view {
    grid-template-columns: 1fr 1fr 1fr; } }

.woocommerce ul.products.products-list-view li.product {
  text-align: left; }

.wtbpContentTable .wtbpAddToCartWrapper p {
  margin: 0; }

ul.products li.product .tinvwl-loop-button-wrapper {
  position: absolute;
  bottom: 100%;
  right: 0;
  padding: 0.5em; }

ul.products li.product .tinvwl_add_to_wishlist_button {
  margin-block-start: 0;
  display: flex;
  align-items: center;
  flex-direction: row-reverse; }

ul.products
li.product
.tinvwl_add_to_wishlist_button
.tinvwl_add_to_wishlist-text {
  font-size: 80%;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.2em 0.4em;
  color: white;
  position: relative;
  margin-inline-end: 0.5em;
  opacity: 0;
  transition: all 0.2s ease; }

ul.products
li.product
.tinvwl_add_to_wishlist_button:hover
.tinvwl_add_to_wishlist-text {
  opacity: 1; }

ul.products
li.product
.tinvwl_add_to_wishlist_button
.tinvwl_add_to_wishlist-text:after {
  margin-inline-start: 0;
  margin-inline-end: -12px;
  border: 6px solid transparent;
  margin-block-end: 0;
  border-top-color: transparent;
  border-left-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  background: transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%); }

ul.products li.product .tinvwl_add_to_wishlist_button:before {
  margin-inline-end: 0;
  background: #fff;
  color: #000;
  border-radius: 50%;
  padding: 0.2em; }

.products.align-buttons-bottom li.product {
  display: flex;
  flex-direction: column; }

.products.align-buttons-bottom li.product .entry-content-wrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column; }

.woocommerce .products.align-buttons-bottom li.product .product-action-wrap {
  margin-block-start: auto;
  padding-block-start: 0.6em; }

ul.products
li.product
.tinvwl-loop-button-wrapper.tinvwl-above_thumb-add-to-cart
.tinvwl_add_to_wishlist_button {
  flex-direction: row; }

ul.products
li.product
.tinvwl-loop-button-wrapper.tinvwl-above_thumb-add-to-cart
.tinvwl_add_to_wishlist_button
.tinvwl_add_to_wishlist-text {
  margin-inline-start: 0.5em;
  margin-inline-end: 0; }

ul.products
li.product
.tinvwl-loop-button-wrapper.tinvwl-above_thumb-add-to-cart
.tinvwl_add_to_wishlist_button
.tinvwl_add_to_wishlist-text:after {
  right: auto;
  left: 0;
  border: 6px solid transparent;
  border-right-color: rgba(0, 0, 0, 0.5);
  margin-inline-start: -12px;
  margin-inline-end: 0; }

.woocommerce .yith-wcwl-icon.fa.fa-heart-o {
  display: none; }

.woocommerce ul .button.yith-wcqv-button:before,
.woocommerce .compare-button a:before,
.woocommerce ul .yith-wcwl-add-to-wishlist .add_to_wishlist:before,
.woocommerce ul .yith-wcwl-wishlistexistsbrowse a:before,
.woocommerce ul .yith-wcwl-wishlistaddedbrowse a:before {
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  content: "";
  position: relative;
  display: inline-block;
  height: 35px;
  width: 35px;
  vertical-align: top;
  background: var(--global-palette1);
  margin-block-end: 5px; }

.woocommerce ul .button.yith-wcqv-button:after,
.woocommerce .compare-button a:after,
.woocommerce ul .yith-wcwl-add-to-wishlist .add_to_wishlist:after,
.woocommerce ul .yith-wcwl-wishlistexistsbrowse a:after,
.woocommerce ul .yith-wcwl-wishlistaddedbrowse a:after {
  background: var(--global-palette2);
  height: 35px;
  display: inline-block;
  content: "";
  position: absolute;
  width: 35px;
  z-index: -9;
  right: 0;
  left: auto;
  top: auto;
  bottom: auto;
  border-radius: 50px; }

.woocommerce ul .button.yith-wcqv-button, .woocommerce ul .yith-wcwl-add-to-wishlist a, .woocommerce.compare-button a {
  margin: 0;
  border: 0 !important;
  background: none !important;
  padding-inline-end: 10px;
  vertical-align: initial;
  color: var(--global-palette3);
  position: relative;
  padding: 0;
  display: inline-block;
  font-weight: 500;
  font-size: 0;
  line-height: 0;
  letter-spacing: 0;
  background: transparent;
  border: transparent;
  border-radius: 0;
  padding: 0;
  margin: 0;
  vertical-align: top; }

.woocommerce ul .yith-wcwl-add-to-wishlist .add_to_wishlist::before, .woocommerce ul .yith-wcwl-wishlistexistsbrowse a::before, .woocommerce ul .yith-wcwl-wishlistaddedbrowse a::before {
  mask-image: url("../images/wishlist.svg");
  -webkit-mask-image: url("../images/wishlist.svg");
  mask-size: 17px;
  -webkit-mask-size: 17px; }

.woocommerce ul .yith-wcwl-wishlistexistsbrowse a::before,
.woocommerce ul .yith-wcwl-wishlistaddedbrowse a::before {
  fill: var(--global-palette1); }

.woocommerce ul .yith-wcwl-wishlistexistsbrowse .feedback,
.woocommerce ul .yith-wcwl-wishlistaddedbrowse .feedback {
  display: none;
  font-size: 0;
  margin: 0;
  padding: 0;
  text-indent: -9999px; }

.woocommerce ul.products li.product .yith-wcqv-button::before, .woocommerce ul .button.yith-wcqv-button::before {
  mask-image: url("../images/quickview.svg");
  -webkit-mask-image: url("../images/quickview.svg");
  mask-size: 15px;
  -webkit-mask-size: 15px; }

.woocommerce .compare-button a:before {
  mask-image: url("../images/compare.svg");
  -webkit-mask-image: url("../images/compare.svg");
  mask-size: 14px;
  -webkit-mask-size: 14px; }

ul.products li.product .product_type_simple::before {
  mask-image: url("../images/addcart.svg");
  -webkit-mask-image: url("../images/addcart.svg");
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 17px;
  -webkit-mask-size: 17px;
  content: "";
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: top;
  background: var(--global-palette1); }

ul.products li.product .product_type_external::before {
  mask-image: url("../images/addcart.svg");
  -webkit-mask-image: url("../images/addcart.svg");
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 17px;
  -webkit-mask-size: 17px;
  content: "";
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: top;
  background: var(--global-palette1); }

ul.products li.product .product_type_grouped::before {
  mask-image: url("../images/addcart.svg");
  -webkit-mask-image: url("../images/addcart.svg");
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 17px;
  -webkit-mask-size: 17px;
  content: "";
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: top;
  background: var(--global-palette1); }

ul.products li.product .product_type_variable::before {
  mask-image: url("../images/addcart.svg");
  -webkit-mask-image: url("../images/addcart.svg");
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 17px;
  -webkit-mask-size: 17px;
  content: "";
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: top;
  background: var(--global-palette1); }

ul.products li.product .product_type_variation::before {
  mask-image: url("../images/addcart.svg");
  -webkit-mask-image: url("../images/addcart.svg");
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 17px;
  -webkit-mask-size: 17px;
  content: "";
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: top;
  background: var(--global-palette1); }

.woocommerce .compare-button a:hover::before {
  background: var(--global-palette1); }

.woocommerce .compare-button a:hover::after {
  background: var(--global-palette2); }

.woocommerce ul .button.yith-wcqv-button:hover::before {
  background: var(--global-palette1); }

.woocommerce ul .button.yith-wcqv-button:hover::after {
  background: var(--global-palette2); }

.woocommerce ul .yith-wcwl-add-to-wishlist .add_to_wishlist:hover::before {
  background: var(--global-palette1); }

.woocommerce ul .yith-wcwl-add-to-wishlist .add_to_wishlist:hover::after {
  background: var(--global-palette2); }

.woocommerce ul .yith-wcwl-wishlistaddedbrowse a:hover::before {
  background: var(--global-palette1); }

.woocommerce ul .yith-wcwl-wishlistaddedbrowse a:hover::after {
  background: var(--global-palette2); }

.woocommerce ul .yith-wcwl-wishlistexistsbrowse a:hover::before {
  background: var(--global-palette1); }

.woocommerce ul .yith-wcwl-wishlistexistsbrowse a:hover::after {
  background: var(--global-palette2); }

.yith-wcwl-add-to-wishlist {
  margin-top: 0px !important;
  display: inline-block; }

.products-list-view .archive-extra-button-wrap {
  top: 100px;
  left: 24%;
  right: auto; }

ul.products .product .button {
  margin-inline-end: 5px; }

ul.products .yith-wcwl-add-to-wishlist {
  margin-inline-end: 5px; }

.archive-extra-button-wrap {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  margin: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-transform: translateX(10px) translateZ(0);
  transform: translateX(10px) translateZ(0);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  z-index: 8; }

ul.products li.product:hover .archive-extra-button-wrap {
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -webkit-transform: translateY(0) translateZ(0);
  transform: translateY(0) translateZ(0); }

.product-details .button.yith-wcqv-button, .product-details .button.compare {
  display: none !important; }

@media screen and (max-width: 767px) {
  ul.products li.product .archive-extra-button-wrap {
    opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -webkit-transform: none;
    transform: none; } }

/**
 * woocommerce-layout.scss
 * Applies layout to the default WooCommerce frontend design
 */
/**
 * Imports
 */
/**
 * Custom Properties
 */
/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
/**
 * Objects
 */
/**
 * Styling begins
 */
.woocommerce,
.woocommerce-page {
  /**
	 * General layout styles
	 */
  /**
	 * Product page
	 */
  /**
	 * Product loops
	 */
  /**
	 * Cart page
	 */
  /**
	 * Cart sidebar
	 */
  /**
	 * Forms
	 */
  /**
	 * oEmbeds
	 */ }

.woocommerce .col2-set,
.woocommerce-page .col2-set {
  *zoom: 1;
  width: 100%; }

.woocommerce .col2-set::before, .woocommerce .col2-set::after,
.woocommerce-page .col2-set::before,
.woocommerce-page .col2-set::after {
  content: ' ';
  display: table; }

.woocommerce .col2-set::after,
.woocommerce-page .col2-set::after {
  clear: both; }

.woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1 {
  float: left;
  width: 48%; }

.woocommerce .col2-set .col-2,
.woocommerce-page .col2-set .col-2 {
  float: right;
  width: 48%; }

.woocommerce img,
.woocommerce-page img {
  height: auto;
  max-width: 100%; }

.woocommerce div.product div.images,
.woocommerce #content div.product div.images,
.woocommerce-page div.product div.images,
.woocommerce-page #content div.product div.images {
  float: left;
  width: 35%; }

.woocommerce div.product div.summary,
.woocommerce #content div.product div.summary,
.woocommerce-page div.product div.summary,
.woocommerce-page #content div.product div.summary {
  float: left;
  width: 43%;
  clear: none; }

.woocommerce div.product .singleproduct-sidebar,
.woocommerce #content div.product .singleproduct-sidebar,
.woocommerce-page div.product .singleproduct-sidebar,
.woocommerce-page #content div.product .singleproduct-sidebar {
  float: right;
  width: 18%;
  clear: none; }

.woocommerce div.product .woocommerce-tabs,
.woocommerce #content div.product .woocommerce-tabs,
.woocommerce-page div.product .woocommerce-tabs,
.woocommerce-page #content div.product .woocommerce-tabs {
  clear: both; }

.woocommerce div.product .woocommerce-tabs ul.tabs,
.woocommerce #content div.product .woocommerce-tabs ul.tabs,
.woocommerce-page div.product .woocommerce-tabs ul.tabs,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs {
  *zoom: 1; }

.woocommerce div.product .woocommerce-tabs ul.tabs::before, .woocommerce div.product .woocommerce-tabs ul.tabs::after,
.woocommerce #content div.product .woocommerce-tabs ul.tabs::before,
.woocommerce #content div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page div.product .woocommerce-tabs ul.tabs::before,
.woocommerce-page div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs::before,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs::after {
  content: ' ';
  display: table; }

.woocommerce div.product .woocommerce-tabs ul.tabs::after,
.woocommerce #content div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs::after {
  clear: both; }

.woocommerce div.product .woocommerce-tabs ul.tabs li,
.woocommerce #content div.product .woocommerce-tabs ul.tabs li,
.woocommerce-page div.product .woocommerce-tabs ul.tabs li,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs li {
  display: inline-block; }

.woocommerce div.product #reviews .comment,
.woocommerce #content div.product #reviews .comment,
.woocommerce-page div.product #reviews .comment,
.woocommerce-page #content div.product #reviews .comment {
  *zoom: 1; }

.woocommerce div.product #reviews .comment::before, .woocommerce div.product #reviews .comment::after,
.woocommerce #content div.product #reviews .comment::before,
.woocommerce #content div.product #reviews .comment::after,
.woocommerce-page div.product #reviews .comment::before,
.woocommerce-page div.product #reviews .comment::after,
.woocommerce-page #content div.product #reviews .comment::before,
.woocommerce-page #content div.product #reviews .comment::after {
  content: ' ';
  display: table; }

.woocommerce div.product #reviews .comment::after,
.woocommerce #content div.product #reviews .comment::after,
.woocommerce-page div.product #reviews .comment::after,
.woocommerce-page #content div.product #reviews .comment::after {
  clear: both; }

.woocommerce div.product #reviews .comment img,
.woocommerce #content div.product #reviews .comment img,
.woocommerce-page div.product #reviews .comment img,
.woocommerce-page #content div.product #reviews .comment img {
  float: right;
  height: auto; }

.woocommerce ul.products,
.woocommerce-page ul.products {
  clear: both; }

.woocommerce ul.products li.product,
.woocommerce-page ul.products li.product {
  position: relative; }

.woocommerce .woocommerce-pagination ul.page-numbers,
.woocommerce-page .woocommerce-pagination ul.page-numbers {
  *zoom: 1; }

.woocommerce .woocommerce-pagination ul.page-numbers::before, .woocommerce .woocommerce-pagination ul.page-numbers::after,
.woocommerce-page .woocommerce-pagination ul.page-numbers::before,
.woocommerce-page .woocommerce-pagination ul.page-numbers::after {
  content: ' ';
  display: table; }

.woocommerce .woocommerce-pagination ul.page-numbers::after,
.woocommerce-page .woocommerce-pagination ul.page-numbers::after {
  clear: both; }

.woocommerce .woocommerce-pagination ul.page-numbers li,
.woocommerce-page .woocommerce-pagination ul.page-numbers li {
  display: inline-block; }

.woocommerce table.cart img,
.woocommerce #content table.cart img,
.woocommerce-page table.cart img,
.woocommerce-page #content table.cart img {
  height: auto; }

.woocommerce table.cart td.actions,
.woocommerce #content table.cart td.actions,
.woocommerce-page table.cart td.actions,
.woocommerce-page #content table.cart td.actions {
  text-align: right; }

.woocommerce table.cart td.actions .coupon,
.woocommerce #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon {
  float: left; }

.woocommerce table.cart td.actions .coupon label,
.woocommerce #content table.cart td.actions .coupon label,
.woocommerce-page table.cart td.actions .coupon label,
.woocommerce-page #content table.cart td.actions .coupon label {
  display: none; }

.woocommerce .cart-collaterals,
.woocommerce-page .cart-collaterals {
  *zoom: 1;
  width: 100%; }

.woocommerce .cart-collaterals::before, .woocommerce .cart-collaterals::after,
.woocommerce-page .cart-collaterals::before,
.woocommerce-page .cart-collaterals::after {
  content: ' ';
  display: table; }

.woocommerce .cart-collaterals::after,
.woocommerce-page .cart-collaterals::after {
  clear: both; }

.woocommerce .cart-collaterals .shipping_calculator,
.woocommerce-page .cart-collaterals .shipping_calculator {
  width: 48%;
  *zoom: 1;
  clear: right;
  float: right; }

.woocommerce .cart-collaterals .shipping_calculator::before, .woocommerce .cart-collaterals .shipping_calculator::after,
.woocommerce-page .cart-collaterals .shipping_calculator::before,
.woocommerce-page .cart-collaterals .shipping_calculator::after {
  content: ' ';
  display: table; }

.woocommerce .cart-collaterals .shipping_calculator::after,
.woocommerce-page .cart-collaterals .shipping_calculator::after {
  clear: both; }

.woocommerce .cart-collaterals .shipping_calculator .col2-set .col-1,
.woocommerce .cart-collaterals .shipping_calculator .col2-set .col-2,
.woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-1,
.woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-2 {
  width: 47%; }

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li,
.woocommerce-page ul.cart_list li,
.woocommerce-page ul.product_list_widget li {
  *zoom: 1; }

.woocommerce ul.cart_list li::before, .woocommerce ul.cart_list li::after,
.woocommerce ul.product_list_widget li::before,
.woocommerce ul.product_list_widget li::after,
.woocommerce-page ul.cart_list li::before,
.woocommerce-page ul.cart_list li::after,
.woocommerce-page ul.product_list_widget li::before,
.woocommerce-page ul.product_list_widget li::after {
  content: ' ';
  display: table; }

.woocommerce ul.cart_list li::after,
.woocommerce ul.product_list_widget li::after,
.woocommerce-page ul.cart_list li::after,
.woocommerce-page ul.product_list_widget li::after {
  clear: both; }

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img,
.woocommerce-page ul.cart_list li img,
.woocommerce-page ul.product_list_widget li img {
  float: right;
  height: auto; }

.woocommerce form .form-row,
.woocommerce-page form .form-row {
  *zoom: 1; }

.woocommerce form .form-row::before, .woocommerce form .form-row::after,
.woocommerce-page form .form-row::before,
.woocommerce-page form .form-row::after {
  content: ' ';
  display: table; }

.woocommerce form .form-row::after,
.woocommerce-page form .form-row::after {
  clear: both; }

.woocommerce form .form-row label,
.woocommerce-page form .form-row label {
  display: block; }

.woocommerce form .form-row label.checkbox,
.woocommerce-page form .form-row label.checkbox {
  display: inline; }

.woocommerce form .form-row select,
.woocommerce-page form .form-row select {
  width: 100%; }

.woocommerce form .form-row .input-text,
.woocommerce-page form .form-row .input-text {
  box-sizing: border-box;
  width: 100%; }

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
  width: 47%;
  overflow: visible; }

.woocommerce form .form-row-first,
.woocommerce-page form .form-row-first {
  float: left;
  /*rtl:raw:
			float: right;
			*/ }

.woocommerce form .form-row-last,
.woocommerce-page form .form-row-last {
  float: right; }

.woocommerce form .form-row-wide,
.woocommerce-page form .form-row-wide {
  clear: both; }

.woocommerce #payment .form-row select,
.woocommerce-page #payment .form-row select {
  width: auto; }

.woocommerce #payment .wc-terms-and-conditions,
.woocommerce #payment .terms,
.woocommerce-page #payment .wc-terms-and-conditions,
.woocommerce-page #payment .terms {
  text-align: left;
  padding: 0 1em 0 0;
  float: left; }

.woocommerce .woocommerce-billing-fields,
.woocommerce .woocommerce-shipping-fields,
.woocommerce-page .woocommerce-billing-fields,
.woocommerce-page .woocommerce-shipping-fields {
  *zoom: 1; }

.woocommerce .woocommerce-billing-fields::before, .woocommerce .woocommerce-billing-fields::after,
.woocommerce .woocommerce-shipping-fields::before,
.woocommerce .woocommerce-shipping-fields::after,
.woocommerce-page .woocommerce-billing-fields::before,
.woocommerce-page .woocommerce-billing-fields::after,
.woocommerce-page .woocommerce-shipping-fields::before,
.woocommerce-page .woocommerce-shipping-fields::after {
  content: ' ';
  display: table; }

.woocommerce .woocommerce-billing-fields::after,
.woocommerce .woocommerce-shipping-fields::after,
.woocommerce-page .woocommerce-billing-fields::after,
.woocommerce-page .woocommerce-shipping-fields::after {
  clear: both; }

.woocommerce .woocommerce-terms-and-conditions,
.woocommerce-page .woocommerce-terms-and-conditions {
  margin-block-end: 1.618em;
  padding: 1.618em; }

.woocommerce .woocommerce-oembed,
.woocommerce-page .woocommerce-oembed {
  position: relative; }

/**
 * RTL styles.
 */
.rtl.woocommerce {
  /**
		* Product page
		*/ }

.rtl.woocommerce .col2-set .col-1 {
  float: right; }

.rtl.woocommerce .col2-set .col-2 {
  float: left; }

.rtl.woocommerce div.product div.images {
  float: right;
  width: 43%; }

.rtl.woocommerce div.product div.images .woocommerce-product-gallery__trigger {
  right: auto;
  left: 0.5rem; }

.rtl.woocommerce div.product div.images .flex-control-thumbs {
  left: auto;
  transform: translate(50%, 0);
  right: 50%; }

.rtl.woocommerce div.product div.summary {
  float: left;
  width: 53%;
  clear: none; }

.rtl.woocommerce div.product #reviews .comment {
  *zoom: 1; }

.rtl.woocommerce div.product #reviews .comment::before, .rtl.woocommerce div.product #reviews .comment::after {
  content: ' ';
  display: table; }

.rtl.woocommerce div.product #reviews .comment::after {
  clear: both; }

.rtl.woocommerce div.product #reviews .comment img {
  float: right;
  height: auto; }

.rtl.woocommerce div.product form.cart div.quantity, .rtl.woocommerce div.product form.cart .button {
  float: right; }

.rtl.woocommerce div.product form.cart div.quantity {
  margin: 0 0 0 4px; }

.rtl.woocommerce span.onsale {
  left: auto;
  right: 0.5rem; }

.rtl.woocommerce .star-rating {
  float: left; }

.rtl.woocommerce .products .star-rating {
  float: left; }

.rtl.woocommerce .woocommerce-product-rating .star-rating {
  float: right; }

.rtl.woocommerce #reviews #comments ol.commentlist li img.avatar {
  float: right;
  left: auto;
  right: 0; }

.rtl.woocommerce #reviews #comments ol.commentlist li .comment-text {
  margin: 0 70px 0 0; }

.rtl.woocommerce .button .thebase-arrow-right-alt-svg {
  transform: rotate(180deg); }

.rtl .woocommerce table.shop_table {
  text-align: right; }

@media screen and (max-width: 1200px) {
  .woocommerce #content div.product div.summary {
    width: 36%; }
  .woocommerce #content div.product .singleproduct-sidebar {
    float: right;
    width: 23%;
    clear: none; }
  .woocommerce div.product div.summary {
    width: 36%; }
  .woocommerce div.product .singleproduct-sidebar {
    float: right;
    width: 23%;
    clear: none; }
  .woocommerce-page #content div.product div.summary {
    width: 36%; }
  .woocommerce-page #content div.product .singleproduct-sidebar {
    float: right;
    width: 23%;
    clear: none; }
  .woocommerce-page div.product div.summary {
    width: 36%; }
  .woocommerce-page div.product .singleproduct-sidebar {
    float: right;
    width: 23%;
    clear: none; } }

@media screen and (max-width: 1024px) {
  .woocommerce-page div.product div.summary {
    width: 45%; }
  .woocommerce-page div.product div.images {
    float: left;
    width: 48%; }
  .woocommerce-page div.product .singleproduct-sidebar {
    float: right;
    width: 100%;
    clear: none;
    display: flex;
    justify-content: center; }
  .woocommerce-page #content div.product div.images {
    float: left;
    width: 48%; }
  .woocommerce #content div.product div.images {
    float: left;
    width: 48%; }
  .woocommerce div.product div.images {
    float: left;
    width: 48%; } }

@media screen and (max-width: 719px) {
  .woocommerce div.product div.summary {
    margin-inline-start: 0 !important; }
  .woocommerce-page div.product .singleproduct-sidebar {
    justify-content: center;
    margin-block-end: 30px; } }

/**
 * woocommerce-layout.scss
 * Applies layout to the default WooCommerce frontend design
 */
/**
 * Imports
 */
/**
 * Custom Properties
 */
/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
/**
 * Objects
 */
form.woocommerce-product-search {
  position: relative; }

form.woocommerce-product-search button[type="submit"] {
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  width: auto;
  border: 0;
  padding: 8px 27px;
  box-shadow: none;
  border-radius: 0;
  overflow: hidden;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

form.woocommerce-product-search input[type="search"] {
  padding-inline-end: 60px;
  width: 100%; }

form.woocommerce-product-search .thebase-search-icon-wrap {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  padding: 0;
  text-align: center;
  background: 0 0;
  z-index: 3;
  cursor: pointer;
  pointer-events: none;
  color: var(--global-palette6);
  text-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center; }

form.woocommerce-product-search button[type="submit"]:hover ~ .thebase-search-icon-wrap {
  color: var(--global-palette4); }

.header-search-bar .woocommerce-product-search .thebase-search-icon-wrap {
  display: none; }

body.rtl .woocommerce-product-search .button[type="submit"],
body.rtl .woocommerce-product-search .thebase-search-icon-wrap {
  left: 0;
  right: auto; }

body.rtl .woocommerce-product-search input[type="search"] {
  padding-inline-end: 0.5em;
  padding-inline-start: 60px; }

.woocommerce {
  /**
      * Forms
      */ }

.woocommerce form .form-row {
  padding: 0;
  margin: 0 0 0.8em; }

.woocommerce form .form-row [placeholder]:focus::-webkit-input-placeholder {
  transition: opacity 0.5s 0.5s ease;
  opacity: 0; }

.woocommerce form .form-row span em {
  font-size: 80%; }

.woocommerce form .form-row label {
  line-height: 2.4;
  color: var(--global-palette4); }

.woocommerce form .form-row label.hidden {
  visibility: hidden; }

.woocommerce form .form-row label.inline {
  display: inline; }

.woocommerce form .form-row .woocommerce-input-wrapper .description {
  background: #1e85be;
  color: #fff;
  padding: 1em;
  margin: 0.5em 0 0;
  clear: both;
  display: none;
  position: relative; }

.woocommerce form .form-row .woocommerce-input-wrapper .description a {
  color: #fff;
  text-decoration: underline;
  border: 0;
  box-shadow: none; }

.woocommerce form .form-row .woocommerce-input-wrapper .description::before {
  left: 50%;
  top: 0%;
  margin-block-start: -4px;
  transform: translateX(-50%) rotate(180deg);
  content: "";
  position: absolute;
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: #1e85be transparent transparent transparent;
  z-index: 100;
  display: block; }

.woocommerce form .form-row select {
  cursor: pointer;
  margin: 0;
  padding: 0.4em 24px 0.4em 0.5em; }

.woocommerce form .form-row .optional {
  visibility: visible; }

.woocommerce form .form-row .input-checkbox {
  display: inline;
  margin: -2px 8px 0 0;
  text-align: center;
  vertical-align: middle; }

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  outline: 0;
  border-radius: 5px; }

.woocommerce form .form-row textarea {
  height: 4em;
  line-height: 1.5;
  display: block;
  box-shadow: none;
  border-radius: 5px; }

.woocommerce form .form-row .select2-container {
  width: 100%;
  line-height: 2em; }

.woocommerce form .form-row.woocommerce-invalid label {
  color: #a00; }

.woocommerce form .form-row.woocommerce-invalid .select2-container,
.woocommerce form .form-row.woocommerce-invalid input.input-text,
.woocommerce form .form-row.woocommerce-invalid select {
  border-color: #a00; }

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
  border-color: #6dc22e; }

.woocommerce form .form-row ::-webkit-input-placeholder {
  line-height: normal; }

.woocommerce form .form-row :-moz-placeholder {
  line-height: normal; }

.woocommerce form .form-row :-ms-input-placeholder {
  line-height: normal; }

.woocommerce .form-row .required {
  color: red;
  font-weight: 700;
  border: 0 !important;
  text-decoration: none;
  visibility: hidden; }

.woocommerce form.login,
.woocommerce form.checkout_coupon,
.woocommerce form.register {
  border: 1px solid var(--global-gray-400);
  padding: 20px;
  margin: 2em 0;
  text-align: left;
  display: inline-block;
  width: 100%; }

.woocommerce form.login .form-row-first,
.woocommerce form.login .form-row-last,
.woocommerce form.checkout_coupon .form-row-first,
.woocommerce form.checkout_coupon .form-row-last,
.woocommerce form.register .form-row-first,
.woocommerce form.register .form-row-last {
  float: none; }

.woocommerce form.checkout_coupon {
  border: 2px dashed var(--global-gray-400); }

.woocommerce ul#shipping_method {
  list-style: none outside;
  margin: 0;
  padding: 0; }

.woocommerce ul#shipping_method li {
  margin: 0 0 0.5em;
  line-height: 1.5em;
  list-style: none outside;
  display: flex;
  align-items: center; }

.woocommerce ul#shipping_method li input {
  margin: 0 0.5em 0 0;
  height: 1em;
  background-image: none;
  vertical-align: top; }

.woocommerce ul#shipping_method li label {
  display: inline; }

.woocommerce ul#shipping_method .amount {
  font-weight: 700; }

.woocommerce p.woocommerce-shipping-contents {
  margin: 0; }

/*--------------------------------------------------------------
# Product Page
--------------------------------------------------------------*/
.product-hero-section .extra-title {
  display: block; }

.woocommerce.single-product.content-style-boxed .product.entry-content-wrap > span.onsale {
  left: 2.5rem;
  top: 2.5rem; }

.rtl.woocommerce.single-product.content-style-boxed .product.entry-content-wrap > span.onsale {
  right: 2.5rem;
  left: auto; }

.woocommerce.single-product.content-style-boxed .products ul.products.woo-archive-action-visible li.product .product-details {
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-end: 0; }

.single-post .content-area {
  margin-block-start: 0; }

.single-product .site div.product form.cart .woocommerce-grouped-product-list-item__quantity .quantity {
  width: 100%; }

.single-product .site div.product form.cart .woocommerce-grouped-product-list-item__quantity .quantity .qty {
  height: 42px; }

.woocommerce div.product:after {
  clear: both;
  display: table;
  content: ''; }

.woocommerce-variation.single_variation {
  display: none; }

.woocommerce-variation.single_variation .woocommerce-variation-price {
  padding: 10px 0 0; }

.single.woocommerce table.shop_attributes {
  border: none;
  border-spacing: 10px;
  margin: 0 -10px; }

.woocommerce .up-sells.products, .woocommerce .related.products {
  clear: both; }

.woocommerce .quantity .qty {
  width: 5em;
  text-align: center;
  padding: 0 0.5em;
  box-sizing: border-box;
  height: 42px; }

.woocommerce .brief-shipping-details {
  font-size: initial;
  color: initial;
  font-weight: initial; }

.woocommerce .woocommerce-product-details__short-description {
  margin-block-end: 2em; }

.woocommerce .single-product-extras {
  margin-block: 1em;
  display: inline-block;
  vertical-align: top;
  width: 100%; }

.woocommerce .single-product-extras ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.woocommerce .single-product-extras ul li {
  line-height: 1.8em; }

.woocommerce .single-product-extras ul .thebase-svg-iconset {
  padding-inline-end: 0.25em; }

.woocommerce .single-product-payments {
  margin-block-end: 2em;
  display: inline-block;
  margin-block-start: 0;
  padding: 0.625em 1.5em 0.625em 1em;
  border-color: var(--global-gray-400); }

.woocommerce .single-product-payments legend {
  padding: 0 0.5em;
  color: var(--global-palette5); }

.woocommerce .single-product-payments ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap; }

.woocommerce .single-product-payments li {
  margin-inline-start: 0.5em;
  margin-block-end: 0.5em; }

.woocommerce .single-product-payments .payment-custom-img {
  max-height: 30px;
  width: auto; }

.woocommerce .single-product-payments .payment-custom-img-gray {
  filter: grayscale(1); }

.woocommerce .single-product-payments .thebase-svg-iconset svg {
  height: 30px;
  width: auto; }

.woocommerce .single-product-payments.payments-color-scheme-gray legend {
  color: var(--global-palette6); }

.woocommerce .single-product-payments.payments-color-scheme-gray .thebase-svg-iconset svg path {
  fill: var(--global-gray-500) !important; }

.woocommerce div.product {
  margin-block-end: 0;
  position: relative; }

.woocommerce div.product .product_title {
  clear: none;
  margin-block-start: 0;
  padding: 0; }

.woocommerce div.product span.price,
.woocommerce div.product p.price {
  font-size: 1.63em;
  font-weight: 400; }

.woocommerce div.product span.price ins,
.woocommerce div.product p.price ins {
  background: inherit;
  display: inline-block; }

.woocommerce div.product span.price del,
.woocommerce div.product p.price del {
  opacity: 0.5;
  display: inline-block; }

.woocommerce div.product .stock {
  color: green; }

.woocommerce div.product .out-of-stock {
  color: red; }

.woocommerce div.product .woocommerce-product-rating {
  margin-block-end: 0.3em; }

.woocommerce div.product div.images {
  margin-block-end: 1em;
  padding-block-end: 1em; }

.woocommerce div.product div.images img {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: none; }

.woocommerce div.product div.images div.thumbnails {
  padding-block-start: 1em; }

.woocommerce div.product div.images.woocommerce-product-gallery {
  position: relative; }

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper {
  transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
  margin: 0;
  padding: 0; }

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper .zoomImg {
  background-color: #fff;
  opacity: 0; }

.woocommerce div.product div.images .woocommerce-product-gallery__image--placeholder {
  border: 1px solid #f2f2f2; }

.woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) {
  display: none; }

.woocommerce div.product div.images .woocommerce-product-gallery__trigger {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 2em;
  z-index: 9;
  width: 36px;
  height: 36px;
  background: #fff;
  text-indent: -9999px;
  border-radius: 100%;
  box-sizing: content-box; }

.woocommerce div.product div.images .woocommerce-product-gallery__trigger::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border: 2px solid #000;
  border-radius: 100%;
  position: absolute;
  top: 9px;
  left: 9px;
  box-sizing: content-box; }

.woocommerce div.product div.images .woocommerce-product-gallery__trigger::after {
  content: "";
  display: block;
  width: 2px;
  height: 8px;
  background: #000;
  border-radius: 6px;
  position: absolute;
  top: 19px;
  left: 22px;
  transform: rotate(-45deg);
  box-sizing: content-box; }

.woocommerce div.product div.images .flex-control-thumbs {
  overflow: hidden;
  zoom: 1;
  margin: 0;
  padding: 0;
  max-width: 94%;
  margin: 0;
  transform: translate(-50%, 0);
  margin-block-start: -32px;
  background: transparent;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
  display: inline-flex;
  justify-content: center;
  position: relative;
  left: 50%; }

.woocommerce div.product div.images .flex-control-thumbs li {
  width: 60px;
  height: 65px;
  margin: 0;
  padding-block-start: 0px;
  padding-block-end: 0px;
  background: var(--global-palette9);
  display: inline-flex;
  list-style: none; }

.woocommerce div.product div.images .flex-control-thumbs li:first-child {
  width: 65px;
  padding-inline-start: 5px; }

.woocommerce div.product div.images .flex-control-thumbs li:nth-child(9) {
  width: 65px;
  padding-inline-start: 5px; }

.woocommerce div.product div.images .flex-control-thumbs li img {
  cursor: pointer;
  opacity: 0.5;
  margin: 0;
  border-top: 5px solid var(--global-palette9);
  border-bottom: 0px solid var(--global-palette9);
  border-right: 5px solid var(--global-palette9);
  border-left: 0; }

.woocommerce div.product div.images .flex-control-thumbs li img.flex-active, .woocommerce div.product div.images .flex-control-thumbs li img:hover {
  opacity: 1; }

.woocommerce div.product div.summary {
  margin-block-end: 2em;
  margin-inline-start: 3em; }

.woocommerce div.product div.summary p {
  margin: 0.3em 0; }

.woocommerce div.product .button.single_add_to_cart_button {
  text-align: center; }

.woocommerce div.product .product_meta {
  margin: 1.2em 0;
  padding: 1.2em 0;
  border-top: 1px solid var(--global-gray-400); }

.woocommerce div.product .product_meta > span {
  padding: 5px 0;
  display: block; }

.woocommerce div.product div.social {
  text-align: right;
  margin: 0 0 1em; }

.woocommerce div.product div.social span {
  margin: 0 0 0 2px; }

.woocommerce div.product div.social span span {
  margin: 0; }

.woocommerce div.product div.social span .stButton .chicklets {
  padding-inline-start: 16px;
  width: 0; }

.woocommerce div.product div.social iframe {
  float: left;
  margin-block-start: 3px; }

.woocommerce div.product .woocommerce-tabs ul.tabs {
  list-style: none;
  margin: 0 0 1.618em;
  overflow: hidden;
  position: relative; }

.woocommerce div.product .woocommerce-tabs ul.tabs li {
  border: 1px solid var(--global-gray-400);
  background-color: var(--global-palette8);
  display: inline-block;
  position: relative;
  z-index: 0;
  margin-inline-end: 10px;
  padding: 0;
  border-radius: 5px; }

.woocommerce div.product .woocommerce-tabs ul.tabs li a {
  display: inline-block;
  padding: 13px 30px;
  color: var(--global-palette5);
  text-decoration: none; }

.woocommerce div.product .woocommerce-tabs ul.tabs li a:hover {
  text-decoration: none;
  color: var(--global-palette1); }

.woocommerce div.product .woocommerce-tabs ul.tabs li.active {
  background: var(--global-palette9);
  z-index: 2;
  border-color: var(--global-palette9); }

.woocommerce div.product .woocommerce-tabs ul.tabs li.active a {
  color: var(--global-palette1);
  text-shadow: inherit;
  background: var(--global-palette2);
  border-radius: 5px; }

.woocommerce div.product .woocommerce-tabs ul.tabs::before {
  position: absolute;
  content: " ";
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid var(--global-gray-400);
  z-index: 1; }

.woocommerce div.product .woocommerce-tabs .panel {
  margin: 0 0 1em;
  padding: 0; }

.woocommerce div.product p.cart {
  margin-block-end: 2em;
  *zoom: 1; }

.woocommerce div.product p.cart::before, .woocommerce div.product p.cart::after {
  content: ' ';
  display: table; }

.woocommerce div.product p.cart::after {
  clear: both; }

.woocommerce div.product form.cart {
  margin-block-end: 2em;
  border-block-start: 1px solid var(--global-gray-400);
  padding-block-start: 2em;
  *zoom: 1; }

.woocommerce div.product form.cart::before, .woocommerce div.product form.cart::after {
  content: ' ';
  display: table; }

.woocommerce div.product form.cart::after {
  clear: both; }

.woocommerce div.product form.cart div.quantity {
  float: left;
  margin: 0 15px 0 0; }

.woocommerce div.product form.cart table {
  border-width: 0 0 1px; }

.woocommerce div.product form.cart table td {
  padding-inline-start: 0; }

.woocommerce div.product form.cart table div.quantity {
  margin: 0; }

.woocommerce div.product form.cart table small.stock {
  display: block;
  float: none; }

.woocommerce div.product form.cart .variations {
  margin-block-end: 1em;
  border: 0;
  width: 100%; }

.woocommerce div.product form.cart .variations td,
.woocommerce div.product form.cart .variations th {
  border: 0;
  vertical-align: top;
  line-height: 2.2em; }

.woocommerce div.product form.cart .variations label {
  display: inline-block;
  margin: 0;
  font-weight: 400;
  width: 100%;
  clear: both;
  margin: 9px 0 0;
  text-align: left;
  vertical-align: middle; }

.woocommerce div.product form.cart .variations select {
  border: 1px solid var(--global-gray-400);
  border-radius: 0;
  display: inline-block;
  font-size: inherit;
  max-width: 100%;
  min-width: 75%;
  margin: 6px 0;
  padding: 8px;
  border-radius: 5px; }

.woocommerce div.product form.cart .variations td.label {
  padding-inline-end: 1em; }

.woocommerce div.product form.cart .woocommerce-variation-description p {
  margin-block-end: 1em; }

.woocommerce div.product form.cart .reset_variations {
  visibility: hidden;
  font-size: 0.83em;
  display: block; }

.woocommerce div.product form.cart .woocommerce-variation-add-to-cart {
  margin-block-start: 0.5em; }

.woocommerce div.product form.cart .woocommerce-variation-add-to-cart:after {
  display: table;
  content: '';
  clear: both; }

.woocommerce div.product form.cart .wc-no-matching-variations {
  display: none; }

.woocommerce div.product form.cart .button {
  vertical-align: middle;
  float: left; }

.woocommerce div.product form.cart .group_table tr {
  display: flex;
  width: 100%;
  align-items: center; }

.woocommerce div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label {
  padding-inline-end: 1em;
  padding-inline-start: 1em;
  vertical-align: middle; }

.woocommerce div.product form.cart .group_table .woocommerce-grouped-product-list-item__price {
  padding-block-start: 0.4em; }

.woocommerce div.product form.cart .group_table td {
  flex: 1;
  padding: .5em .5em .5em 0; }

.woocommerce div.product form.cart .group_table td:first-child {
  width: auto;
  text-align: center;
  min-width: 128px; }

.woocommerce div.product form.cart .group_table .wc-grouped-product-add-to-cart-checkbox {
  display: inline-block;
  width: auto;
  margin: 0 auto;
  transform: scale(1.5, 1.5); }

.woocommerce section.products > h2 {
  margin-block-end: 0.5em; }

.woocommerce span.onsale {
  min-height: 0;
  min-width: 40px;
  padding: .35em .4em;
  position: absolute;
  text-align: center;
  line-height: 1em;
  top: .4rem;
  left: .4rem;
  margin: 0;
  border-radius: 0;
  background-color: #00a046;
  color: var(--global-palette9);
  border-radius: 50px;
  font-size: 12px;
  z-index: 9; }

.woocommerce.product-tab-style-center div.product .woocommerce-tabs ul.tabs {
  text-align: center;
  padding: 0; }

.woocommerce.product-tab-style-center div.product .woocommerce-tabs ul.tabs li {
  border: 0;
  border-bottom: 3px solid transparent;
  margin: 0 0.6em;
  background: transparent; }

.woocommerce.product-tab-style-center div.product .woocommerce-tabs ul.tabs li a {
  padding-inline-start: 0;
  padding-inline-end: 0; }

.woocommerce.product-tab-style-center div.product .woocommerce-tabs ul.tabs li.active {
  border-bottom: 3px solid var(--global-palette-highlight); }

.woocommerce.product-variation-style-vertical div.product form.cart .variations td.label {
  padding: 0; }

.woocommerce.product-variation-style-vertical div.product form.cart .variations tr, .woocommerce.product-variation-style-vertical div.product form.cart table td {
  display: block; }

.woocommerce.product-variation-style-vertical div.product form.cart .variations select {
  min-width: 240px;
  margin: 0; }

.thebase-cart-button-large.woocommerce div.product .button.single_add_to_cart_button {
  height: 60px;
  font-size: 125%;
  width: 70%; }

.thebase-cart-button-large.woocommerce div.product form.cart div.quantity {
  width: 30%;
  margin: 0;
  padding: 0;
  padding-inline-end: 0.5em; }

.thebase-cart-button-large.woocommerce div.product form.cart div.quantity .qty {
  width: 100%;
  height: 60px; }

.thebase-cart-button-large.woocommerce div.product form.cart div.quantity.spinners-added {
  width: 35%;
  display: flex;
  padding: 0;
  margin: 0 2% 0 0; }

.thebase-cart-button-large.woocommerce div.product form.cart div.quantity.spinners-added .qty {
  width: 0;
  flex-grow: 1;
  height: 58px; }

.thebase-cart-button-large.woocommerce div.product form.cart div.quantity.spinners-added ~ .button.single_add_to_cart_button {
  width: 63%; }

.thebase-cart-button-medium-large.woocommerce div.product .button.single_add_to_cart_button {
  height: 50px;
  font-size: 125%;
  width: 70%; }

.thebase-cart-button-medium-large.woocommerce div.product form.cart div.quantity {
  width: 30%;
  margin: 0;
  padding: 0 0.5em 0 0; }

.thebase-cart-button-medium-large.woocommerce div.product form.cart div.quantity .qty {
  width: 100%;
  height: 50px; }

.thebase-cart-button-medium-large.woocommerce div.product form.cart div.quantity.spinners-added {
  width: 35%;
  display: flex;
  padding: 0;
  margin: 0 2% 0 0; }

.thebase-cart-button-medium-large.woocommerce div.product form.cart div.quantity.spinners-added .qty {
  width: 0;
  flex-grow: 1;
  height: 48px; }

.thebase-cart-button-medium-large.woocommerce div.product form.cart div.quantity.spinners-added ~ .button.single_add_to_cart_button {
  width: 63%; }

@media screen and (min-width: 640px) {
  .thebase-cart-button-large.woocommerce div.product .button.single_add_to_cart_button {
    width: 85%; }
  .thebase-cart-button-large.woocommerce div.product form.cart div.quantity {
    width: 15%; }
  .thebase-cart-button-large.woocommerce div.product form.cart div.quantity.spinners-added {
    width: 25%; }
  .thebase-cart-button-large.woocommerce div.product form.cart div.quantity.spinners-added ~ .button.single_add_to_cart_button {
    width: 73%; }
  .thebase-cart-button-medium-large.woocommerce div.product .button.single_add_to_cart_button {
    width: 65%; }
  .thebase-cart-button-medium-large.woocommerce div.product form.cart div.quantity {
    width: 15%; }
  .thebase-cart-button-medium-large.woocommerce div.product form.cart div.quantity.spinners-added {
    width: 25%; } }

.single-product .thebase-breadcrumbs {
  margin-block-start: -1rem; }

.single-product.content-vertical-padding-hide .thebase-breadcrumbs, .single-product.content-vertical-padding-block-end .thebase-breadcrumbs {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em; }

.content-style-unboxed .entry-content-wrap.product:not(.loop-entry) {
  padding: 0; }

.content-style-unboxed .content-bg.loop-entry .content-bg:not(.loop-entry) {
  background: var(--global-palette9); }

.woocommerce div.product div.thebase-product-image-wrap.images .images {
  width: auto;
  float: none; }

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper:after {
  clear: both;
  display: table;
  content: ''; }

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:nth-child(n+2) {
  display: inline-block;
  height: 65px;
  width: 33%;
  margin-block-start: -31px; }

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:nth-child(n+2) img {
  height: 65px;
  margin-inline-end: auto;
  margin-inline-start: auto; }

.woocommerce div.product div.images .flex-viewport .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:nth-child(n+2) {
  margin-block-start: 0;
  height: auto;
  width: auto; }

.woocommerce div.product div.images .flex-viewport .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:nth-child(n+2) img {
  height: auto; }

.woocommerce div.product form.cart .woocommerce-grouped-product-list .woocommerce-grouped-product-list-item .quantity.spinners-added {
  width: auto; }

.woocommerce div.product form.cart .woocommerce-grouped-product-list .woocommerce-grouped-product-list-item div.quantity.spinners-added .qty {
  width: 1.8em; }

.woocommerce.base-cart-button-normal div.product div.summary .added_to_cart {
  margin-top: 0;
  margin-left: 0.5em; }

.thebase-sticky-add-to-cart-action-wrap .single_add_to_cart_button {
  height: 42px;
  line-height: 42px;
  padding: 0em 1em; }

.woocommerce .thebase-sticky-add-to-cart-action-wrap a.added_to_cart {
  margin: 0 0 0 0.5em; }

.woocommerce div.product div.summary .added_to_cart:hover, .woocommerce .thebase-sticky-add-to-cart-action-wrap a.added_to_cart:hover {
  background: var(--global-palette9); }

.thebase-cart-button-medium-large.woocommerce div.product div.summary .added_to_cart {
  height: 50px;
  line-height: 50px;
  font-size: 125%;
  width: 100%; }

.thebase-cart-button-large.woocommerce div.product div.summary .added_to_cart {
  height: 60px;
  line-height: 60px;
  font-size: 125%;
  width: 100%; }

.quantity.spinners-added {
  border: 1px solid var(--global-gray-400);
  background: var(--global-palette9);
  border-radius: 5px;
  transition: all 0.2s ease; }

.quantity.spinners-added .qty::-webkit-inner-spin-button,
.quantity.spinners-added .qty::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.quantity.spinners-added:hover {
  border-color: var(--global-gray-400); }

.quantity.spinners-added input.minus, .quantity.spinners-added input.plus {
  background: transparent;
  color: var(--global-palette5);
  box-shadow: none;
  padding: 0 0.8em;
  line-height: 40px;
  font-size: 20px;
  border: 0; }

.quantity.spinners-added input.minus:hover, .quantity.spinners-added input.plus:hover {
  background: transparent;
  box-shadow: none;
  color: var(--global-palette5); }

.quantity.hidden {
  display: none !important; }

.woocommerce .quantity.spinners-added .qty {
  width: 3em;
  text-align: center;
  border: 0;
  padding: 0;
  box-shadow: none;
  height: 42px;
  -moz-appearance: textfield; }

@media screen and (max-width: 1024px) {
  .woocommerce div.product div.images .flex-control-thumbs li:nth-child(7) {
    width: 65px;
    padding-inline-start: 5px; }
  .woocommerce div.product div.images .flex-control-thumbs li:nth-child(9) {
    width: 60px;
    padding-inline-start: 0px; } }

@media screen and (max-width: 768px) {
  .woocommerce div.product div.images .flex-control-thumbs li:nth-child(6) {
    width: 65px;
    padding-inline-start: 5px; }
  .woocommerce div.product div.images .flex-control-thumbs li:nth-child(7) {
    width: 60px;
    padding-left: 0px; }
  .woocommerce div.product form.cart .variations select {
    min-width: 100%; } }

/**
 * woocommerce-layout.scss
 * Applies layout to the default WooCommerce frontend design
 */
/**
 * Imports
 */
/**
 * Custom Properties
 */
/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
/**
 * Objects
 */
.woocommerce-account .account-navigation-wrap {
  margin-block-end: 1.5em; }

.woocommerce-account .account-navigation-wrap .thebase-customer-image {
  position: absolute;
  left: calc(1em + 4px);
  top: 0; }

.woocommerce-account .account-navigation-wrap .thebase-customer-image img {
  border-radius: 50%;
  max-width: 40px;
  position: absolute;
  left: 0;
  top: 0; }

.woocommerce-account .account-navigation-wrap .thebase-account-avatar {
  position: relative;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-inline-start: calc( 1em + 4px);
  margin-block-end: 0.5em;
  margin-block-start: 0.5em; }

.woocommerce-account .account-navigation-wrap .thebase-account-avatar .thebase-customer-name {
  padding-inline-start: 50px; }

.woocommerce-account .account-navigation-wrap li a {
  display: block;
  padding: 0.5em 1.5em 0.5em 1em;
  color: inherit;
  text-decoration: none;
  border-left: 5px solid transparent;
  margin-inline-start: -1px; }

.woocommerce-account .account-navigation-wrap li:hover a {
  border-left: 5px solid var(--global-gray-400); }

.woocommerce-account .account-navigation-wrap li.is-active a, .woocommerce-account .account-navigation-wrap li.is-active a:hover {
  border-left: 5px solid var(--global-palette-highlight-alt);
  font-weight: 500;
  color: var(--global-palette-highlight-alt); }

.woocommerce-account .account-navigation-wrap ul {
  padding-inline-start: 0em;
  list-style: none; }

.woocommerce-account .woocommerce-MyAccount-content .woocommerce-notices-wrapper:empty + * {
  margin-block-start: 0; }

.woocommerce-account .woocommerce-MyAccount-content .u-columns {
  margin-block-start: var(--global-md-spacing);
  margin-block-end: 0; }

@media screen and (min-width: 768px) {
  .woocommerce-account .account-navigation-wrap {
    float: right;
    width: 30%;
    border-left: 1px solid var(--global-gray-400);
    margin-block-end: 0; }
  .woocommerce-account .woocommerce-MyAccount-content {
    float: left;
    width: 70%;
    padding-inline-end: var(--global-lg-spacing); }
  .thebase-account-nav-left.woocommerce-account .account-navigation-wrap {
    float: left;
    border-right: 1px solid var(--global-gray-400);
    border-left: 0; }
  .thebase-account-nav-left.woocommerce-account .account-navigation-wrap li a {
    padding: 0.5em 1em 0.5em 1.5em;
    border-left: 0;
    border-right: 5px solid transparent;
    margin-inline-end: -1px;
    margin-inline-start: 0; }
  .thebase-account-nav-left.woocommerce-account .account-navigation-wrap li:hover a {
    border-left: 0;
    border-right: 5px solid var(--global-gray-400); }
  .thebase-account-nav-left.woocommerce-account .account-navigation-wrap li.is-active a, .thebase-account-nav-left.woocommerce-account .account-navigation-wrap li.is-active a:hover {
    border-left: 0;
    border-right: 5px solid var(--global-palette-highlight); }
  .thebase-account-nav-left.woocommerce-account .woocommerce-MyAccount-content {
    float: right;
    padding-inline-start: var(--global-lg-spacing);
    padding-inline-end: 0; }
  .thebase-account-nav-above.woocommerce-account .account-navigation-wrap {
    float: none;
    border-left: 0;
    width: auto;
    margin-block-end: 1.5em; }
  .thebase-account-nav-above.woocommerce-account .account-navigation-wrap ul {
    display: flex;
    flex-wrap: wrap; }
  .thebase-account-nav-above.woocommerce-account .account-navigation-wrap li a {
    padding: 0.2em 0.5em 0.2em 0.5em;
    border-left: 0;
    border-bottom: 3px solid transparent;
    margin-inline-start: 0; }
  .thebase-account-nav-above.woocommerce-account .account-navigation-wrap li:hover a {
    border-left: 0;
    border-bottom: 3px solid var(--global-gray-400); }
  .thebase-account-nav-above.woocommerce-account .account-navigation-wrap li.is-active a, .thebase-account-nav-above.woocommerce-account .account-navigation-wrap li.is-active a:hover {
    border-left: 0;
    border-bottom: 3px solid var(--global-palette-highlight); }
  .thebase-account-nav-above.woocommerce-account .woocommerce-MyAccount-content {
    float: none;
    width: auto;
    padding-inline-end: 0; } }

.woocommerce form[class*="woocommerce-"] {
  margin-block-start: 0; }

.woocommerce-MyAccount-content mark {
  color: inherit;
  background: transparent;
  font-weight: bold; }

/**
  * Account page
  */
.woocommerce-account .woocommerce {
  *zoom: 1; }

.woocommerce-account .woocommerce::before, .woocommerce-account .woocommerce::after {
  content: ' ';
  display: table; }

.woocommerce-account .woocommerce::after {
  clear: both; }

.woocommerce-account .addresses .title {
  *zoom: 1; }

.woocommerce-account .addresses .title::before, .woocommerce-account .addresses .title::after {
  content: ' ';
  display: table; }

.woocommerce-account .addresses .title::after {
  clear: both; }

.woocommerce-account .addresses .title h3 {
  float: left; }

.woocommerce-account .addresses .title .edit {
  float: right;
  padding: 0.3em 0; }

.woocommerce-account ol.commentlist.notes li.note p.meta {
  font-weight: 500;
  margin-block-end: 0; }

.woocommerce-account ol.commentlist.notes li.note .description p:last-child {
  margin-block-end: 0; }

.woocommerce-account ul.digital-downloads {
  margin-inline-start: 0;
  padding-inline-start: 0; }

.woocommerce-account ul.digital-downloads li {
  list-style: none;
  margin-inline-start: 0;
  padding-inline-start: 0; }

.woocommerce-account ul.digital-downloads li::before {
  font-family: 'WooCommerce';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-inline-end: 0.618em;
  content: "";
  text-decoration: none; }

.woocommerce-account ul.digital-downloads li .count {
  float: right; }

.woo-sl-info mark {
  background: var(--global-palette7);
  font-weight: bold; }

p.woo-sl-key {
  margin-block-start: var(--global-xs-spacing);
  padding: 0.5em;
  background: var(--global-palette8);
  display: inline-block; }

#woo_sl_lk {
  margin-block-start: 1em; }

#woo_sl_lk p.woo-sl-label {
  padding: 0.5em 1em;
  background: var(--global-palette8);
  display: block;
  margin: 0;
  line-height: 1.8; }

#woo_sl_lk .inside .licence_expire {
  border-color: var(--global-gray-400); }

#sl_generator_desc {
  margin-block-end: 1em; }

#woo-sl-key-generator.software_license_key_generator p {
  margin-block-start: 1em;
  margin-block-end: 1em; }

#woo-sl-key-generator.software_license_key_generator #sl_product_id {
  padding: 0.2em 35px 0.2em 0.5em; }

.woocommerce #woo_sl_lk table.my_account_orders th {
  padding: 9px 12px; }

.rtl.woocommerce-account .account-navigation-wrap ul {
  padding-inline-end: 0; }

.rtl.woocommerce-account .account-navigation-wrap ul li a {
  padding: 0.5em 1em 0.5em 1.5em; }

.rtl.woocommerce-account .account-navigation-wrap .thebase-account-avatar .thebase-customer-name {
  padding-inline-end: 50px;
  padding-inline-start: 0; }

.rtl.woocommerce-account .account-navigation-wrap .thebase-customer-image {
  right: calc(1em + 4px);
  left: auto; }

.rtl.woocommerce-account.thebase-account-nav-left .account-navigation-wrap .thebase-account-avatar {
  padding-inline-end: calc( 1em + 4px);
  padding-inline-start: 0; }

.rtl.woocommerce-account.thebase-account-nav-left .account-navigation-wrap .thebase-customer-image a {
  display: inline-block;
  width: 40px; }

.rtl .woocommerce .woocommerce-message, .rtl .woocommerce .woocommerce-error, .rtl .woocommerce .woocommerce-info {
  border-right: 5px solid var(--global-palette-highlight);
  border-left: 0; }

.rtl .woocommerce .woocommerce-message .button, .rtl .woocommerce .woocommerce-error .button, .rtl .woocommerce .woocommerce-info .button {
  float: left; }

.rtl .woocommerce .woocommerce-info {
  border-right-color: var(--global-palette-highlight); }

.rtl .woocommerce .woocommerce-error {
  border-right-color: #b81c23; }

.rtl.woocommerce-account .addresses .title h3 {
  float: right; }

.rtl.woocommerce-account .addresses .title .edit {
  float: left; }

.rtl .woocommerce form.login, .rtl .woocommerce form.checkout_coupon, .rtl .woocommerce form.register {
  text-align: right; }

/**
  * Cart/checkout page
  */
.woocommerce table.shop_table td .quantity.spinners-added {
  display: inline-block;
  align-items: center; }

.woocommerce .cart-empty.woocommerce-info {
  margin-block-start: var(--global-xxl-spacing);
  margin-block-end: var(--global-sm-spacing);
  padding: 0;
  background: transparent;
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  border: 0;
  color: var(--global-palette6); }

.woocommerce .cart-empty.woocommerce-info:before, .woocommerce .cart-empty.woocommerce-info:after {
  display: none; }

.woocommerce .return-to-shop {
  margin-block-start: var(--global-sm-spacing);
  margin-block-end: var(--global-xxl-spacing);
  text-align: center; }

.woocommerce-cart .coupon .input-text {
  width: 200px; }

.woocommerce-cart table.cart,
.woocommerce-checkout table.cart,
#add_payment_method table.cart {
  position: relative; }

.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img,
#add_payment_method table.cart img {
  min-width: 60px;
  box-shadow: none; }

.woocommerce-cart table.cart .product-name a,
.woocommerce-checkout table.cart .product-name a,
#add_payment_method table.cart .product-name a {
  color: var(--global-palette5); }

.woocommerce-cart table.cart .product-name a:hover,
.woocommerce-checkout table.cart .product-name a:hover,
#add_payment_method table.cart .product-name a:hover {
  color: var(--global-palette3); }

.woocommerce-cart table.cart th,
.woocommerce-cart table.cart td,
.woocommerce-checkout table.cart th,
.woocommerce-checkout table.cart td,
#add_payment_method table.cart th,
#add_payment_method table.cart td {
  vertical-align: middle; }

.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce-checkout table.cart td.actions .coupon .input-text,
#add_payment_method table.cart td.actions .coupon .input-text {
  float: left;
  box-sizing: border-box;
  padding: 0.4em 0.6em;
  margin: 0 4px 0 0;
  outline: 0;
  height: 45px;
  border-radius: 5px; }

.woocommerce-cart table.cart input,
.woocommerce-checkout table.cart input,
#add_payment_method table.cart input {
  margin: 0;
  vertical-align: middle; }

.woocommerce-cart .wc-proceed-to-checkout,
.woocommerce-checkout .wc-proceed-to-checkout,
#add_payment_method .wc-proceed-to-checkout {
  *zoom: 1;
  padding: 1em 0; }

.woocommerce-cart .wc-proceed-to-checkout::before, .woocommerce-cart .wc-proceed-to-checkout::after,
.woocommerce-checkout .wc-proceed-to-checkout::before,
.woocommerce-checkout .wc-proceed-to-checkout::after,
#add_payment_method .wc-proceed-to-checkout::before,
#add_payment_method .wc-proceed-to-checkout::after {
  content: ' ';
  display: table; }

.woocommerce-cart .wc-proceed-to-checkout::after,
.woocommerce-checkout .wc-proceed-to-checkout::after,
#add_payment_method .wc-proceed-to-checkout::after {
  clear: both; }

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
#add_payment_method .wc-proceed-to-checkout a.checkout-button {
  display: block;
  text-align: center; }

.woocommerce-cart .cart-collaterals,
.woocommerce-checkout .cart-collaterals,
#add_payment_method .cart-collaterals {
  background: var(--global-palette7);
  padding: 30px; }

.woocommerce-cart .cart-collaterals .shipping-calculator-button,
.woocommerce-checkout .cart-collaterals .shipping-calculator-button,
#add_payment_method .cart-collaterals .shipping-calculator-button {
  float: none;
  margin-block-start: 0.5em;
  display: inline-block;
  text-decoration: underline; }

.woocommerce-cart .cart-collaterals .shipping-calculator-form,
.woocommerce-checkout .cart-collaterals .shipping-calculator-form,
#add_payment_method .cart-collaterals .shipping-calculator-form {
  margin: 1em 0 0 0; }

.woocommerce-cart .cart-collaterals .cart_totals p small,
.woocommerce-checkout .cart-collaterals .cart_totals p small,
#add_payment_method .cart-collaterals .cart_totals p small {
  color: var(--global-palette6);
  font-size: 0.83em; }

.woocommerce-cart .cart-collaterals .cart_totals table,
.woocommerce-checkout .cart-collaterals .cart_totals table,
#add_payment_method .cart-collaterals .cart_totals table {
  border-collapse: separate;
  margin: 0 0 6px;
  padding: 0;
  border: 0; }

.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td,
.woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child th,
.woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child td,
#add_payment_method .cart-collaterals .cart_totals table tr:first-child th,
#add_payment_method .cart-collaterals .cart_totals table tr:first-child td {
  border-top: 0; }

.woocommerce-cart .cart-collaterals .cart_totals table th,
.woocommerce-checkout .cart-collaterals .cart_totals table th,
#add_payment_method .cart-collaterals .cart_totals table th {
  width: 35%; }

.woocommerce-cart .cart-collaterals .cart_totals table td,
.woocommerce-cart .cart-collaterals .cart_totals table th,
.woocommerce-checkout .cart-collaterals .cart_totals table td,
.woocommerce-checkout .cart-collaterals .cart_totals table th,
#add_payment_method .cart-collaterals .cart_totals table td,
#add_payment_method .cart-collaterals .cart_totals table th {
  vertical-align: top;
  border-left: 0;
  border-right: 0;
  line-height: 1.5em;
  padding-inline-start: 0; }

.woocommerce-cart .cart-collaterals .cart_totals table small,
.woocommerce-checkout .cart-collaterals .cart_totals table small,
#add_payment_method .cart-collaterals .cart_totals table small {
  color: var(--global-palette6); }

.woocommerce-cart .cart-collaterals .cart_totals table select,
.woocommerce-checkout .cart-collaterals .cart_totals table select,
#add_payment_method .cart-collaterals .cart_totals table select {
  width: 100%; }

.woocommerce-cart .cart-collaterals .cart_totals .discount td,
.woocommerce-checkout .cart-collaterals .cart_totals .discount td,
#add_payment_method .cart-collaterals .cart_totals .discount td {
  color: var(--global-palette-highlight); }

.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th,
.woocommerce-checkout .cart-collaterals .cart_totals tr td,
.woocommerce-checkout .cart-collaterals .cart_totals tr th,
#add_payment_method .cart-collaterals .cart_totals tr td,
#add_payment_method .cart-collaterals .cart_totals tr th {
  border-top: 1px solid var(--global-gray-400); }

.woocommerce-cart .cart-collaterals .cart_totals .woocommerce-shipping-destination,
.woocommerce-checkout .cart-collaterals .cart_totals .woocommerce-shipping-destination,
#add_payment_method .cart-collaterals .cart_totals .woocommerce-shipping-destination {
  margin-block-end: 0; }

.woocommerce-cart .cart-collaterals .cross-sells ul.products li.product,
.woocommerce-checkout .cart-collaterals .cross-sells ul.products li.product,
#add_payment_method .cart-collaterals .cross-sells ul.products li.product {
  margin-block-start: 0; }

.woocommerce-cart .checkout .col-2 h3#ship-to-different-address,
.woocommerce-checkout .checkout .col-2 h3#ship-to-different-address,
#add_payment_method .checkout .col-2 h3#ship-to-different-address {
  float: left;
  clear: none; }

.woocommerce-cart .checkout .col-2 .woocommerce-form__label-for-checkbox,
.woocommerce-checkout .checkout .col-2 .woocommerce-form__label-for-checkbox,
#add_payment_method .checkout .col-2 .woocommerce-form__label-for-checkbox {
  display: flex;
  align-items: center; }

.woocommerce-cart .checkout .col-2 .woocommerce-form__label-for-checkbox [type="checkbox"],
.woocommerce-checkout .checkout .col-2 .woocommerce-form__label-for-checkbox [type="checkbox"],
#add_payment_method .checkout .col-2 .woocommerce-form__label-for-checkbox [type="checkbox"] {
  margin-inline-end: .5em; }

.woocommerce-cart .checkout .col-2 .notes,
.woocommerce-checkout .checkout .col-2 .notes,
#add_payment_method .checkout .col-2 .notes {
  clear: left; }

.woocommerce-cart .checkout .col-2 .form-row-first,
.woocommerce-checkout .checkout .col-2 .form-row-first,
#add_payment_method .checkout .col-2 .form-row-first {
  clear: left; }

.woocommerce-cart .checkout .create-account small,
.woocommerce-checkout .checkout .create-account small,
#add_payment_method .checkout .create-account small {
  font-size: 11px;
  color: var(--global-palette6);
  font-weight: normal; }

.woocommerce-cart .checkout div.shipping-address,
.woocommerce-checkout .checkout div.shipping-address,
#add_payment_method .checkout div.shipping-address {
  padding: 0;
  clear: left;
  width: 100%; }

.woocommerce-cart .checkout .shipping_address,
.woocommerce-checkout .checkout .shipping_address,
#add_payment_method .checkout .shipping_address {
  clear: both; }

.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods,
#add_payment_method #payment ul.payment_methods {
  *zoom: 1;
  text-align: left;
  padding: 0;
  margin: 0;
  list-style: none outside; }

.woocommerce-cart #payment ul.payment_methods::before, .woocommerce-cart #payment ul.payment_methods::after,
.woocommerce-checkout #payment ul.payment_methods::before,
.woocommerce-checkout #payment ul.payment_methods::after,
#add_payment_method #payment ul.payment_methods::before,
#add_payment_method #payment ul.payment_methods::after {
  content: ' ';
  display: table; }

.woocommerce-cart #payment ul.payment_methods::after,
.woocommerce-checkout #payment ul.payment_methods::after,
#add_payment_method #payment ul.payment_methods::after {
  clear: both; }

.woocommerce-cart #payment ul.payment_methods li,
.woocommerce-checkout #payment ul.payment_methods li,
#add_payment_method #payment ul.payment_methods li {
  line-height: 2;
  text-align: left;
  margin: 0;
  font-weight: normal; }

.woocommerce-cart #payment ul.payment_methods li input,
.woocommerce-checkout #payment ul.payment_methods li input,
#add_payment_method #payment ul.payment_methods li input {
  margin: 0 1em 0 0; }

.woocommerce-cart #payment ul.payment_methods li img,
.woocommerce-checkout #payment ul.payment_methods li img,
#add_payment_method #payment ul.payment_methods li img {
  vertical-align: middle;
  margin: -2px 0 0 0.5em;
  padding: 0;
  position: relative;
  box-shadow: none;
  display: inline-block; }

.woocommerce-cart #payment ul.payment_methods li img + img,
.woocommerce-checkout #payment ul.payment_methods li img + img,
#add_payment_method #payment ul.payment_methods li img + img {
  margin-inline-start: 2px; }

.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice),
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice),
#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice) {
  *zoom: 1; }

.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::before, .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::before,
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after,
#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::before,
#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after {
  content: ' ';
  display: table; }

.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after,
#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after {
  clear: both; }

.woocommerce-cart #payment .woocommerce-terms-and-conditions-wrapper,
.woocommerce-checkout #payment .woocommerce-terms-and-conditions-wrapper,
#add_payment_method #payment .woocommerce-terms-and-conditions-wrapper {
  margin-block-start: var(--global-md-spacing); }

.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box,
#add_payment_method #payment div.payment_box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0em;
  margin: 1em 0;
  font-size: 0.92em;
  border-radius: 2px;
  line-height: 1.5;
  background-color: transparent;
  color: var(--global-palette4); }

.woocommerce-cart #payment div.payment_box input.input-text,
.woocommerce-cart #payment div.payment_box textarea,
.woocommerce-checkout #payment div.payment_box input.input-text,
.woocommerce-checkout #payment div.payment_box textarea,
#add_payment_method #payment div.payment_box input.input-text,
#add_payment_method #payment div.payment_box textarea {
  border-color: var(--global-gray-400);
  border-top-color: var(--global-gray-400); }

.woocommerce-cart #payment div.payment_box ::-webkit-input-placeholder,
.woocommerce-checkout #payment div.payment_box ::-webkit-input-placeholder,
#add_payment_method #payment div.payment_box ::-webkit-input-placeholder {
  color: var(--global-gray-400); }

.woocommerce-cart #payment div.payment_box :-moz-placeholder,
.woocommerce-checkout #payment div.payment_box :-moz-placeholder,
#add_payment_method #payment div.payment_box :-moz-placeholder {
  color: var(--global-gray-400); }

.woocommerce-cart #payment div.payment_box :-ms-input-placeholder,
.woocommerce-checkout #payment div.payment_box :-ms-input-placeholder,
#add_payment_method #payment div.payment_box :-ms-input-placeholder {
  color: var(--global-gray-400); }

.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods {
  list-style: none outside;
  margin: 0; }

.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new {
  margin: 0 0 0.5em; }

.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label {
  cursor: pointer; }

.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput {
  vertical-align: middle;
  margin: -3px 1em 0 0;
  position: relative; }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form,
#add_payment_method #payment div.payment_box .wc-credit-card-form {
  border: 0;
  padding: 0;
  margin: 1em 0 0; }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc {
  font-size: 1.5em;
  padding: 8px;
  background-repeat: no-repeat;
  background-position: right 0.618em center;
  background-size: 32px 20px; }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.visa,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.visa,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.visa {
  background-image: url("../images/icons/credit-cards/visa.svg"); }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard {
  background-image: url("../images/icons/credit-cards/mastercard.svg"); }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.laser,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.laser,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.laser {
  background-image: url("../images/icons/credit-cards/laser.svg"); }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub {
  background-image: url("../images/icons/credit-cards/diners.svg"); }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.maestro,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.maestro,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.maestro {
  background-image: url("../images/icons/credit-cards/maestro.svg"); }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.jcb,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.jcb,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.jcb {
  background-image: url("../images/icons/credit-cards/jcb.svg"); }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.amex,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.amex,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.amex {
  background-image: url("../images/icons/credit-cards/amex.svg"); }

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.discover,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.discover,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.discover {
  background-image: url("../images/icons/credit-cards/discover.svg"); }

.woocommerce-cart #payment div.payment_box span.help,
.woocommerce-checkout #payment div.payment_box span.help,
#add_payment_method #payment div.payment_box span.help {
  font-size: 0.857em;
  color: var(--global-palette6);
  font-weight: normal; }

.woocommerce-cart #payment div.payment_box .form-row,
.woocommerce-checkout #payment div.payment_box .form-row,
#add_payment_method #payment div.payment_box .form-row {
  margin: 0 0 0.8em; }

.woocommerce-cart #payment div.payment_box p:last-child,
.woocommerce-checkout #payment div.payment_box p:last-child,
#add_payment_method #payment div.payment_box p:last-child {
  margin-block-end: 0; }

.woocommerce-cart #payment .payment_method_paypal .about_paypal,
.woocommerce-checkout #payment .payment_method_paypal .about_paypal,
#add_payment_method #payment .payment_method_paypal .about_paypal {
  float: right;
  line-height: 52px;
  font-size: 0.83em; }

.woocommerce-cart #payment .payment_method_paypal img,
.woocommerce-checkout #payment .payment_method_paypal img,
#add_payment_method #payment .payment_method_paypal img {
  max-height: 52px;
  vertical-align: middle; }

.rtl.woocommerce-cart .checkout .col-2 h3#ship-to-different-address, .rtl.woocommerce-checkout .checkout .col-2 h3#ship-to-different-address,
.rtl #add_payment_method .checkout .col-2 h3#ship-to-different-address {
  float: right; }

.rtl.woocommerce-cart .checkout .col-2 .woocommerce-form__label-for-checkbox [type="checkbox"], .rtl.woocommerce-checkout .checkout .col-2 .woocommerce-form__label-for-checkbox [type="checkbox"],
.rtl #add_payment_method .checkout .col-2 .woocommerce-form__label-for-checkbox [type="checkbox"] {
  margin-inline-start: .5em;
  margin-inline-end: 0; }

.rtl.woocommerce-cart #payment ul.payment_methods, .rtl.woocommerce-checkout #payment ul.payment_methods,
.rtl #add_payment_method #payment ul.payment_methods {
  text-align: right; }

.rtl.woocommerce-cart #payment ul.payment_methods li, .rtl.woocommerce-checkout #payment ul.payment_methods li,
.rtl #add_payment_method #payment ul.payment_methods li {
  text-align: right; }

.rtl.woocommerce-cart #payment ul.payment_methods li img, .rtl.woocommerce-checkout #payment ul.payment_methods li img,
.rtl #add_payment_method #payment ul.payment_methods li img {
  vertical-align: middle;
  margin: -2px 0 0 0.5em;
  padding: 0;
  position: relative;
  box-shadow: none;
  display: inline-block; }

.rtl.woocommerce-cart #payment ul.payment_methods li img + img, .rtl.woocommerce-checkout #payment ul.payment_methods li img + img,
.rtl #add_payment_method #payment ul.payment_methods li img + img {
  margin-inline-start: 2px; }

.rtl.woocommerce-cart #payment .payment_method_paypal .about_paypal, .rtl.woocommerce-checkout #payment .payment_method_paypal .about_paypal,
.rtl #add_payment_method #payment .payment_method_paypal .about_paypal {
  float: left; }

.rtl #payment ul.wc_payment_methods {
  padding-inline-end: 0; }

.rtl #payment ul.wc_payment_methods li.wc_payment_method input[type=radio]:first-child:checked + label {
  border-right: 5px solid var(--global-palette-highlight);
  border-left: 0; }

.rtl #payment ul.wc_payment_methods li.wc_payment_method > label {
  border-right: 5px solid var(--global-gray-400);
  border-left: 0; }

.rtl #payment ul.wc_payment_methods li.wc_payment_method > label:before {
  margin-inline-end: -5px;
  margin-inline-start: 4px; }

.woocommerce-terms-and-conditions {
  border: 1px solid var(--global-gray-400);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background: var(--global-palette8); }

.thebase-woo-cart-form-wrap, .cross-sells {
  *zoom: 1; }

.thebase-woo-cart-form-wrap::before, .thebase-woo-cart-form-wrap::after, .cross-sells::before, .cross-sells::after {
  content: ' ';
  display: table; }

.thebase-woo-cart-form-wrap::after, .cross-sells::after {
  clear: both; }

@media screen and (min-width: 768px) {
  .woocommerce-cart table.cart .product-thumbnail,
  .woocommerce-checkout table.cart .product-thumbnail,
  #add_payment_method table.cart .product-thumbnail {
    width: 74px; }
  .woocommerce-cart table.cart th.product-name,
  .woocommerce-checkout table.cart th.product-name,
  #add_payment_method table.cart th.product-name {
    position: absolute;
    left: 0;
    top: 0; }
  .woocommerce-cart table.cart .product-remove,
  .woocommerce-checkout table.cart .product-remove,
  #add_payment_method table.cart .product-remove {
    width: 24px; }
  .rtl.woocommerce-cart table.cart th.product-name, .rtl.woocommerce-checkout table.cart th.product-name,
  .rtl #add_payment_method table.cart th.product-name {
    right: 0;
    left: auto; } }

#payment #place_order {
  float: none;
  width: 100%;
  box-sizing: border-box;
  margin: var(--global-md-spacing) 0 1em; }

#payment ul.wc_payment_methods {
  list-style: none;
  padding-left: 0;
  background: var(--global-palette9); }

#payment ul.wc_payment_methods li.wc_payment_method > input[type=radio]:first-child {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important; }

#payment ul.wc_payment_methods li.wc_payment_method input[type=radio]:first-child:checked + label {
  border-left: 5px solid var(--global-palette-highlight);
  background: var(--global-palette7); }

#payment ul.wc_payment_methods li.wc_payment_method input[type=radio]:first-child:checked + label:before {
  background: var(--global-palette-highlight);
  border-color: var(--global-palette-highlight); }

#payment ul.wc_payment_methods li.wc_payment_method > label {
  background: var(--global-palette8);
  display: block;
  border-left: 5px solid var(--global-gray-400);
  padding: 8px 15px;
  transition: border 0.2s ease-in-out;
  margin-block-end: 4px;
  font-weight: bold; }

#payment ul.wc_payment_methods li.wc_payment_method > label:before {
  content: '';
  background: transparent;
  width: 10px;
  display: inline-block;
  border: 1px solid var(--global-gray-500);
  height: 10px;
  border-radius: 50%;
  margin-inline-start: -5px;
  margin-inline-end: 4px; }

#payment ul.wc_payment_methods li.wc_payment_method > label:hover {
  border-left: 5px solid var(--global-gray-500);
  background: var(--global-palette7); }

#payment ul.wc_payment_methods li:first-child:last-child > label {
  border: 0 !important; }

#payment ul.wc_payment_methods li:first-child:last-child > label:before {
  display: none; }

#payment .payment_box {
  position: relative;
  width: 100%;
  padding: 15px;
  font-size: 95%; }

#payment .payment_box p:last-child {
  margin-block-end: 0; }

form.checkout .woocommerce-checkout-review-order {
  float: left; }

form.checkout .col2-set {
  background: var(--global-palette7);
  padding: var(--global-md-spacing); }

@media screen and (min-width: 1025px) {
  .woocommerce-checkout .woocommerce form.checkout_coupon,
  .woocommerce-checkout .woocommerce form.login {
    width: 50%; }
  .woocommerce-checkout .woocommerce form.checkout_coupon form .form-row,
  .woocommerce-checkout .woocommerce form.login form .form-row {
    width: 100%; } }

@media screen and (min-width: 1025px) {
  .thebase-woo-cart-form-wrap > form {
    float: left;
    width: 70%;
    padding-inline-end: var(--global-lg-spacing); }
  .thebase-woo-cart-form-wrap .cart-collaterals {
    float: left;
    width: 30%; }
  form.checkout {
    *zoom: 1; }
  form.checkout::before, form.checkout::after {
    content: ' ';
    display: table; }
  form.checkout::after {
    clear: both; }
  form.checkout .col2-set {
    float: left;
    width: 50%; }
  form.checkout .col2-set .col-1, form.checkout .col2-set .col-2 {
    width: 100%;
    float: none;
    padding: 0;
    clear: both; }
  form.checkout .col2-set .col-2 {
    margin-block-start: var(--global-md-spacing); }
  form.checkout #order_review_heading {
    float: left;
    width: 50%;
    padding-inline-start: var(--global-md-spacing);
    margin: 0 0 .5em; }
  form.checkout .woocommerce-checkout-review-order {
    width: 50%;
    padding-inline-start: var(--global-md-spacing);
    float: left; } }

@media screen and (min-width: 1025px) {
  .rtl table.cart td.actions .coupon .input-text, .rtl #add_payment_method table.cart td.actions .coupon .input-text {
    float: right;
    margin: 0 0 0 4px; }
  .rtl .thebase-woo-cart-form-wrap > form {
    float: right;
    padding-inline-start: var(--global-lg-spacing);
    padding-inline-end: 0; }
  .rtl .thebase-woo-cart-form-wrap .cart-collaterals {
    float: right; }
  .rtl form.checkout .col2-set {
    float: right;
    padding-inline-start: var(--global-md-spacing);
    padding-inline-end: 0; }
  .rtl form.checkout #order_review_heading {
    float: right;
    padding-inline-end: var(--global-md-spacing);
    padding-inline-start: 0; }
  .rtl form.checkout .woocommerce-checkout-review-order {
    padding-inline-start: 0;
    padding-inline-end: var(--global-md-spacing);
    float: right; } }

.rtl table.cart td.actions .coupon .input-text, .rtl #add_payment_method table.cart td.actions .coupon .input-text {
  float: right;
  margin: 0 0 0 4px; }

body .select2-container .select2-selection--single,
body .select2-container--default .select2-selection--single, .widget select {
  border: 1px solid var(--global-gray-400);
  border-radius: 0;
  overflow: hidden;
  height: 42px; }

body .select2-container--default .select2-selection--single .select2-selection__placeholder,
body .select2-container--default .select2-selection--single .select2-selection__rendered {
  background-color: var(--global-palette9);
  padding-inline: 10px;
  overflow: hidden;
  line-height: 42px;
  color: var(--global-palette1); }

body .select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  padding-inline: 0; }

body .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 42px;
  right: 6px; }

.select2-container--default .select2-results__option[aria-selected=true], .select2-container--default .select2-results__option[data-selected=true] {
  background-color: var(--global-palette7); }

.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option--highlighted[data-selected] {
  background: var(--global-palette-btn-bg);
  color: var(--global-palette-btn); }

.select2-dropdown {
  border-color: var(--global-gray-400);
  color: var(--global-palette4);
  background: var(--global-palette9); }

.select2-container--open .select2-dropdown--below {
  box-shadow: 0px 15px 25px -7px rgba(0, 0, 0, 0.1); }

.select2-container--open .select2-dropdown--above {
  box-shadow: 0px -15px 25px -7px rgba(0, 0, 0, 0.1); }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: var(--global-gray-400); }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: var(--global-gray-400) transparent transparent transparent; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent var(--global-gray-400) transparent; }

.woocommerce-privacy-policy-text {
  margin-bottom: 20px; }

.wc-block-components-totals-coupon.wc-blocks-components-panel .wc-blocks-components-panel__button {
  margin: .375em 0; }

.wc-block-components-sidebar .wc-block-components-totals-coupon.wc-blocks-components-panel > h2 {
  font-size: 1em; }

.wc-block-components-checkout-form .wc-block-components-checkout-step__description {
  margin-block-end: 16px; }

.single-content .woocommerce-notices-wrapper ul.woocommerce-error, .single-content .woocommerce-notices-wrapper ul.woocommerce-info, .single-content .woocommerce-notices-wrapper ul.woocommerce-success {
  margin-block-end: var(--global-md-spacing); }

.rtl.woocommerce table.cart td.actions, .rtl.woocommerce #content table.cart td.actions, .rtl.woocommerce-page table.cart td.actions, .rtl.woocommerce-page #content table.cart td.actions {
  text-align: left; }

.rtl.woocommerce table.cart td.actions .coupon, .rtl.woocommerce #content table.cart td.actions .coupon, .rtl.woocommerce-page table.cart td.actions .coupon, .rtl.woocommerce-page #content table.cart td.actions .coupon {
  float: right; }

.rtl .woocommerce .woocommerce-form-login .woocommerce-form-login__submit {
  float: right;
  margin-inline-end: 0;
  margin-inline-start: 1em; }

.rtl .woocommerce form .form-row.form-row-last {
  float: left; }

.rtl .woocommerce form .form-row.form-row-first {
  float: right; }

/*--------------------------------------------------------------
# Woo Blocks
--------------------------------------------------------------*/
/**
 * Custom Star font.
 */
.wc-block-grid__product .wc-block-grid__product-title {
  font-size: 1em;
  padding-block-start: 0.5rem;
  font-weight: 500;
  margin-block-end: 0.5rem;
  font-family: var(--global-heading-font-family); }

.entry-content .wc-block-grid__products {
  margin: 0 0 1em;
  padding: 0; }

.wc-block-grid .wc-block-grid__products {
  margin: 0 0 1em;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2.5rem;
  row-gap: 2.5rem;
  list-style: none; }

.wc-block-grid .wc-block-grid__products .woocommerce-loop-image-link img {
  width: 100%; }

.wc-block-grid .wc-block-grid__products:not(.thisisforspecificity) .wc-block-grid__product {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  width: 100%;
  max-width: 100%;
  flex: 1;
  text-align: left;
  overflow: hidden; }

.wc-block-grid__product.entry.loop-entry.content-bg.woo-archive-action-on-hover .wc-block-grid__product-add-to-cart {
  width: auto;
  position: absolute;
  bottom: -2rem;
  left: 0;
  margin-block-start: 0;
  padding: 0 1rem;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.17, 0.67, 0.35, 0.95); }

.wc-block-grid__product.entry.loop-entry.content-bg.woo-archive-action-on-hover:hover .wc-block-grid__product-add-to-cart, .wc-block-grid__product.entry.loop-entry.content-bg.woo-archive-action-on-hover:focus-within .wc-block-grid__product-add-to-cart {
  bottom: -.8rem;
  opacity: 1; }

.wc-block-grid__product.entry.loop-entry.content-bg.woo-archive-action-on-hover:hover .entry-content-wrap, .wc-block-grid__product.entry.loop-entry.content-bg.woo-archive-action-on-hover:focus-within .entry-content-wrap {
  transform: translateY(-2rem); }

#wrapper .wc-block-grid .wc-block-grid__products .product-loop-boxed .entry-content-wrap, #wrapper .wc-block-grid .wc-block-grid__products .woo-archive-action-on-hover .entry-content-wrap {
  padding: 1rem 1rem 1.5rem;
  position: relative;
  margin: 0 .5em;
  border-radius: .25rem;
  transition: transform 0.3s cubic-bezier(0.17, 0.67, 0.35, 0.95); }

.has-2-columns .wc-block-grid__products {
  grid-template-columns: 1fr 1fr; }

.has-3-columns .wc-block-grid__products {
  grid-template-columns: 1fr 1fr 1fr; }

.has-4-columns .wc-block-grid__products {
  grid-template-columns: 1fr 1fr 1fr 1fr; }

.has-5-columns .wc-block-grid__products {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }

.has-6-columns .wc-block-grid__products {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; }

@media screen and (max-width: 768px) {
  .has-4-columns .wc-block-grid__products {
    grid-template-columns: 1fr 1fr; }
  .has-6-columns .wc-block-grid__products {
    grid-template-columns: 1fr 1fr 1fr; } }

@media screen and (max-width: 719px) {
  .site .wc-block-grid .wc-block-grid__products {
    margin: 0 0 1em;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr; }
  .site .wc-block-grid .wc-block-grid__products .wc-block-grid__product {
    margin-inline-start: 0;
    margin-inline-end: 0; } }

.wc-block-grid .wc-block-grid__product .wc-block-grid__product-image {
  margin-block-end: 0; }

.wc-block-grid .wc-block-grid__product .product-details .wp-block-button {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0; }

.wc-block-grid.has-aligned-buttons .wc-block-grid__product .product-details.content-bg.entry-content-wrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }

.wc-block-grid.has-aligned-buttons .wc-block-grid__product .product-details .wp-block-button {
  margin-block-start: auto; }

.wc-block-grid.has-aligned-buttons .wc-block-grid__product .product-details.content-bg.entry-content-wrap {
  padding-block-end: 1.5rem; }

.wc-block-grid .wc-block-grid__product .entry-content-wrap {
  padding: 1.5rem; }

body.content-style-unboxed .wc-block-grid .wc-block-grid__product .entry-content-wrap, .wc-block-grid .wc-block-grid__product.product-loop-unboxed .entry-content-wrap {
  padding: 1rem 0;
  background: transparent; }

.wc-block-grid .wc-block-grid__product.product-loop-unboxed {
  background: transparent; }

.wc-block-grid__product a.wc-block-grid__product-link, .wc-block-grid__product a.wc-block-grid__product-title-link {
  text-decoration: none;
  color: inherit; }

.wc-block-grid__product a.wc-block-grid__product-link {
  position: relative;
  display: block; }

.wc-block-grid__product:not(.woo-archive-image-hover-none) a.wc-block-grid__product-link {
  overflow: hidden; }

.wc-block-grid__product {
  text-align: left; }

.wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-price {
  margin: 0 0 1em; }

.wc-block-grid__product-price {
  font-weight: normal;
  font-size: 0.857em; }

.wc-block-grid__product-price del {
  color: inherit;
  opacity: 0.5; }

.wc-block-grid__product-price ins {
  background: none;
  font-weight: 700; }

.wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-onsale {
  min-height: 0;
  min-width: 0;
  padding: .5em 1em;
  font-weight: 700;
  position: absolute;
  text-align: center;
  line-height: 1.2;
  top: 6px;
  right: 6px;
  left: auto;
  margin: 0;
  border-radius: .25rem;
  background-color: var(--global-palette-highlight);
  color: var(--global-palette-btn);
  font-size: 0.857em;
  z-index: 9;
  border: 0; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart {
  color: inherit; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link .thebase-svg-iconset {
  display: inline-flex;
  align-self: center; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link .thebase-svg-iconset svg {
  top: .125em;
  position: relative;
  height: 1em;
  width: 1em; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link .thebase-spinner-svg, .wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link .thebase-check-svg {
  display: none; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link.loading {
  opacity: 0.25; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link.loading .thebase-arrow-right-alt-svg {
  display: none; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link.loading .thebase-spinner-svg {
  display: block;
  animation: spin 2s linear infinite; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link.loading::after {
  display: none; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link.added {
  font-size: 0; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link.added .thebase-svg-iconset:last-child {
  font-size: 1rem; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link.added .thebase-arrow-right-alt-svg {
  display: none; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link.added .thebase-check-svg {
  display: block;
  font-size: 80%; }

.wc-block-grid__product .wc-block-grid__product-add-to-cart .wp-block-button__link.added::after {
  display: none; }

.wc-block-grid__product.woo-archive-btn-button .wc-block-grid__product-add-to-cart .wp-block-button__link {
  color: var(--global-palette-btn);
  text-align: center;
  display: block;
  width: 100%;
  transition: color 0.2s ease, background 0.2s ease, border 0.2s ease; }

.wc-block-grid__product.woo-archive-btn-button .wc-block-grid__product-add-to-cart .wp-block-button__link:hover {
  color: var(--global-palette-btn-hover);
  background: var(--global-palette-btn-bg-hover); }

.wc-block-grid__product.woo-archive-btn-button .wc-block-grid__product-add-to-cart .wp-block-button__link.added {
  height: 0;
  overflow: hidden; }

.wc-block-grid__product:not(.woo-archive-btn-button) .wc-block-grid__product-add-to-cart .wp-block-button__link {
  background: transparent;
  padding: 0.5em 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .05em;
  color: inherit;
  border: 0;
  box-shadow: none;
  display: inline-block;
  transition: color 0.2s ease; }

.wc-block-grid__product .wc-block-grid__product-rating {
  float: right;
  margin-block-start: 0;
  margin-block-end: 0; }

.wc-block-grid__product .wc-block-grid__product-rating .star-rating {
  margin: 0.25em auto 0.25em; }

.wc-block-grid.has-aligned-buttons .wc-block-grid__product .wc-block-grid__product-rating {
  margin-block-end: 12px;
  margin-inline-start: 0; }

.wc-block-grid.has-aligned-buttons .wc-block-grid__product .wc-block-grid__product-rating .star-rating {
  margin-inline-start: 0; }

.wc-block-grid__product .wc-block-grid__product-rating ~ .wc-block-grid__product-price {
  padding-inline-end: 5.4em; }

.wc-block-grid__product-rating .star-rating, .wc-block-grid__product-rating .wc-block-grid__product-rating__stars, .wc-block-review-list .wc-block-components-review-list-item__rating > .wc-block-components-review-list-item__rating__stars {
  font-family: "star";
  display: block;
  margin: 0.25em auto 0.25em;
  margin-inline-start: 0;
  font-size: 1em;
  line-height: 1;
  height: 1em;
  width: 5em;
  color: var(--global-palette-highlight); }

.wc-block-grid__product-rating .star-rating:before, .wc-block-grid__product-rating .wc-block-grid__product-rating__stars:before, .wc-block-review-list .wc-block-components-review-list-item__rating > .wc-block-components-review-list-item__rating__stars:before {
  content: "\e900\e900\e900\e900\e900";
  color: var(--global-gray-400);
  float: left;
  top: 0;
  left: 0;
  opacity: 1;
  right: auto;
  position: absolute; }

.wc-block-grid__product-rating .star-rating span, .wc-block-grid__product-rating .wc-block-grid__product-rating__stars span, .wc-block-review-list .wc-block-components-review-list-item__rating > .wc-block-components-review-list-item__rating__stars span {
  float: left;
  right: auto; }

.wc-block-grid__product-rating .star-rating span:before, .wc-block-grid__product-rating .wc-block-grid__product-rating__stars span:before, .wc-block-review-list .wc-block-components-review-list-item__rating > .wc-block-components-review-list-item__rating__stars span:before {
  content: "\e901\e901\e901\e901\e901";
  right: auto;
  color: inherit; }

li.product a .secondary-product-image, .wc-block-grid__product a .secondary-product-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  opacity: 0;
  min-width: 100%;
  min-height: 100%; }

.products:not(.woo-archive-image-hover-none) li.product a {
  overflow: hidden; }

.woo-archive-image-hover-fade .woocommerce-loop-product__link .attachment-woocommerce_thumbnail {
  transition: opacity 0.3s ease; }

.woo-archive-image-hover-fade .woocommerce-loop-product__link:hover .attachment-woocommerce_thumbnail {
  opacity: 1; }

.woo-archive-image-hover-zoom .woocommerce-loop-product__link .attachment-woocommerce_thumbnail {
  transform: scale(1);
  transition: opacity 0.4s ease, transform 2s cubic-bezier(0, 0, 0.4, 1.1); }

.woo-archive-image-hover-zoom .woocommerce-loop-product__link:hover .attachment-woocommerce_thumbnail {
  opacity: 1;
  transform: scale(1.07); }

.woo-archive-image-hover-slide .woocommerce-loop-product__link .attachment-woocommerce_thumbnail.secondary-product-image {
  transform: translate(100%, 0);
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.17, 0.67, 0.35, 0.95);
  display: block; }

.woo-archive-image-hover-slide .woocommerce-loop-product__link .attachment-woocommerce_thumbnail:not(.secondary-product-image) {
  transform: translate(0);
  transition: all 0.4s cubic-bezier(0.17, 0.67, 0.35, 0.95);
  display: block; }

.woo-archive-image-hover-slide .woocommerce-loop-product__link.product-has-hover-image:hover .attachment-woocommerce_thumbnail:not(.secondary-product-image) {
  transform: translate(-100%, 0); }

.woo-archive-image-hover-slide .woocommerce-loop-product__link.product-has-hover-image:hover .attachment-woocommerce_thumbnail.secondary-product-image {
  transform: translate(0, 0); }

.woo-archive-image-hover-flip .woocommerce-loop-product__link .attachment-woocommerce_thumbnail {
  perspective: 1000px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: all .5s ease-in-out;
  display: block; }

.woo-archive-image-hover-flip .woocommerce-loop-product__link .attachment-woocommerce_thumbnail.secondary-product-image {
  transform: rotateY(180deg);
  opacity: 1; }

.woo-archive-image-hover-flip .woocommerce-loop-product__link .attachment-woocommerce_thumbnail:not(.secondary-product-image) {
  transform: rotateY(0deg); }

.woo-archive-image-hover-flip .woocommerce-loop-product__link.product-has-hover-image:hover .attachment-woocommerce_thumbnail:not(.secondary-product-image) {
  transform: rotateY(180deg); }

.woo-archive-image-hover-flip .woocommerce-loop-product__link.product-has-hover-image:hover .attachment-woocommerce_thumbnail.secondary-product-image {
  transform: rotateY(360deg); }

.wc-block-components-price-slider__range-input-wrapper {
  background-color: var(--global-gray-400);
  border-radius: 1em;
  box-shadow: none;
  max-width: calc( 100% - 4px);
  margin-inline-start: 2px; }

.wc-block-components-price-slider__range-input-wrapper .wc-block-components-price-slider__range-input-progress {
  --range-color: var(--global-palette-highlight);
  border-radius: 1em; }

.wc-block-components-price-slider__range-input-wrapper .wc-block-components-price-slider__range-input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 0;
  display: block;
  position: relative;
  pointer-events: none;
  outline: none !important;
  position: absolute;
  left: 0;
  top: 0; }

@font-face {
  font-family: 'star';
  src: url("../fonts/star.woff2?gmcsp2") format("woff2"), url("../fonts/star.ttf?gmcsp2") format("truetype"), url("../fonts/star.woff?gmcsp2") format("woff"), url("../fonts/star.svg?gmcsp2#star") format("svg");
  font-weight: normal;
  font-style: normal; }

.thebase-header-cart.header-navigation .header-cart-button, .thebase-header-cart .header-cart-button {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0;
  border: 0;
  box-shadow: none; }

.thebase-header-cart.header-navigation .header-cart-button:hover, .thebase-header-cart .header-cart-button:hover {
  background: transparent;
  color: inherit;
  box-shadow: none; }

.header-cart-button .thebase-svg-iconset {
  font-size: 1.2em; }

.header-cart-button .header-cart-label:empty {
  padding-inline-end: 0px; }

.header-cart-wrap .header-cart-empty-check {
  display: none; }

.widget-toggle-open .widget-toggle-label {
  padding-inline-end: 0; }

.widget-toggle-open .widget-toggle-icon {
  padding-inline-end: 20px; }

.hot-product-carousel ul.products {
  width: 100%;
  float: left;
  margin: 0; }

.hot-product ul.products li:hover .container-inner {
  box-shadow: none; }

.header-cart-button .header-cart-total {
  font-size: 0.95em;
  height: 1.7em;
  line-height: 1;
  border-radius: 50%;
  display: flex;
  width: 1.7em;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--global-palette1);
  background: var(--global-palette2);
  position: absolute;
  right: auto;
  left: 16px;
  top: -6px;
  margin-inline-start: 0.1em;
  transition: all 0.2s ease; }

.woocommerce-tabs li > a {
  font-weight: 500; }

#cart-drawer.popup-drawer .drawer-inner {
  max-width: 400px;
  background: var(--global-palette9);
  color: var(--global-palette1); }

@media screen and (max-width: 719px) {
  #cart-drawer.popup-drawer .drawer-inner {
    max-width: 100%; } }

#cart-drawer h2.side-cart-header {
  font-size: 1.2em;
  letter-spacing: 0; }

.site-header-item .header-cart-wrap .header-cart-inner-wrap .header-cart-button {
  background: none; }

#cart-drawer.popup-drawer .drawer-header {
  justify-content: space-between;
  align-items: center; }

#cart-drawer.popup-drawer .drawer-content {
  padding-block-start: 1em; }

.site-header-section-left .thebase-header-cart.header-navigation ul ul.sub-menu {
  left: 0;
  right: auto; }

.thebase-header-cart.header-navigation .header-cart-button {
  padding: 0.6em 0; }

.thebase-header-cart.header-navigation ul.cart_list {
  display: block;
  position: static;
  box-shadow: none;
  background: transparent;
  transform: none;
  opacity: 1;
  visibility: visible;
  height: auto;
  clip: auto;
  max-height: 100vh;
  overflow: auto; }

.thebase-header-cart.header-navigation .menu-item.menu-item-thebase-cart-dropdown {
  min-width: 340px;
  padding: 1em;
  color: var(--global-palette4); }

.thebase-header-cart.header-navigation ul p.woocommerce-mini-cart__empty-message {
  margin: 0; }

.thebase-header-cart.header-navigation ul ul.sub-menu {
  right: 0;
  background: var(--global-palette9);
  color: var(--global-palette4);
  left: auto; }

.thebase-header-cart.header-navigation ul ul a:not(.button) {
  padding: 0;
  width: auto;
  font-size: inherit;
  color: var(--global-palette4);
  background: transparent; }

.thebase-header-cart.header-navigation ul ul a:not(.button):hover {
  color: var(--global-palette3);
  background: transparent; }

.thebase-header-cart.header-navigation ul ul a.remove:hover {
  background: red;
  text-decoration: none; }

.thebase-header-cart.header-navigation ul li a.remove.remove_from_cart_button {
  padding: 0; }

.thebase-header-cart.header-navigation ul .woocommerce-mini-cart__buttons {
  margin-block-end: 0; }

.thebase-header-cart.header-navigation ul a.button {
  background: var(--global-palette-btn-bg);
  color: var(--global-palette-btn);
  display: inline-block;
  width: 100%; }

.thebase-header-cart.header-navigation ul a.button:hover {
  color: var(--global-palette-btn-hover);
  background: var(--global-palette-btn-bg-hover); }

.rtl .thebase-header-cart.header-navigation ul ul.sub-menu {
  right: auto;
  left: 0; }

/**
 * Custom WooCommerce Animations.
 */
@keyframes spin {
  100% {
    transform: rotate(360deg); } }

/**
  * Global styles
  */
p.demo_store,
.woocommerce-store-notice {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  font-size: 1em;
  padding: 1em;
  text-align: center;
  background-color: var(--global-palette-highlight);
  color: var(--global-palette-btn);
  z-index: 99998;
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: none; }

p.demo_store a,
.woocommerce-store-notice a {
  color: var(--global-palette-btn);
  text-decoration: underline; }

p.demo_store a.woocommerce-store-notice__dismiss-link,
.woocommerce-store-notice a.woocommerce-store-notice__dismiss-link {
  float: right;
  font-size: 0;
  mask-image: url(../images/close.svg);
  -webkit-mask-image: url(../images/close.svg);
  mask-size: 26px;
  -webkit-mask-size: 26px;
  background: #fff;
  position: relative;
  display: inline-block;
  height: 20px;
  width: 35px;
  vertical-align: top;
  background: var(--global-palette9);
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat; }

p.demo_store img,
.woocommerce-store-notice img {
  display: inline-block; }

@media screen and (max-width: 719px) {
  p.demo_store a.woocommerce-store-notice__dismiss-link,
  .woocommerce-store-notice a.woocommerce-store-notice__dismiss-link {
    float: none;
    margin-block-start: 0.5em;
    margin-block-end: 0;
    display: block; } }

.admin-bar p.demo_store {
  top: 32px; }

.thebase-store-notice-placement-above p.woocommerce-store-notice.demo_store {
  position: static;
  box-shadow: none; }

.thebase-store-notice-placement-bottom .woocommerce-store-notice {
  bottom: 0;
  top: auto !important;
  position: fixed; }

/**
  * Utility classes
  */
.clear {
  clear: both; }

/**
  * Main WooCommerce styles
  */
.woocommerce {
  /**
      * Buttons
      */
  /**
      * Reviews
      */
  /**
      * Star ratings
      */
  /**
      * Tables
      */
  /**
      * Cart sidebar
      */
  /**
      * Order page
      */
  /**
      * Layered nav widget
      */
  /**
      * Categories Widget.
      */
  /**
      * Price filter widget
      */
  /**
      * Rating Filter Widget
      */ }

.woocommerce mark.count {
  background: transparent;
  color: inherit; }

.woocommerce .blockUI.blockOverlay {
  position: relative; }

.woocommerce .blockUI.blockOverlay::before {
  height: 1em;
  width: 1em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-inline-start: -0.5em;
  margin-block-start: -0.5em;
  content: '';
  animation: spin 1s ease-in-out infinite;
  background: url("../images/icons/loader.svg") center center;
  background-size: cover;
  line-height: 1;
  text-align: center;
  font-size: 2em;
  color: rgba(0, 0, 0, 0.75); }

.woocommerce .loader::before {
  height: 1em;
  width: 1em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-inline-start: -0.5em;
  margin-block-start: -0.5em;
  content: '';
  animation: spin 1s ease-in-out infinite;
  background: url("../images/icons/loader.svg") center center;
  background-size: cover;
  line-height: 1;
  text-align: center;
  font-size: 2em;
  color: rgba(0, 0, 0, 0.75); }

.woocommerce a.remove {
  display: block;
  font-size: 18px;
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 22px;
  border-radius: 100%;
  color: red !important;
  text-decoration: none;
  font-weight: 500;
  border: 0;
  font-family: system-ui; }

.woocommerce a.remove:hover {
  color: #fff !important;
  background: red;
  text-decoration: none; }

.woocommerce small.note {
  display: block;
  color: var(--global-palette6);
  font-size: 0.857em;
  margin-block-start: 10px; }

.woocommerce .woocommerce-breadcrumb {
  *zoom: 1;
  margin: 0 0 1em;
  padding: 0;
  font-size: 0.92em;
  color: var(--global-palette6); }

.woocommerce .woocommerce-breadcrumb::before, .woocommerce .woocommerce-breadcrumb::after {
  content: ' ';
  display: table; }

.woocommerce .woocommerce-breadcrumb::after {
  clear: both; }

.woocommerce .woocommerce-breadcrumb a {
  color: var(--global-palette6); }

.woocommerce a.button.loading,
.woocommerce button.button.loading,
.woocommerce input.button.loading,
.woocommerce #respond input#submit.loading {
  opacity: 0.25; }

.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce #respond input#submit.alt {
  background-color: var(--global-palette-btn-bg-hover);
  border-color: var(--global-palette-btn-bg-hover);
  color: var(--global-palette-btn-hover);
  -webkit-font-smoothing: antialiased; }

.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover,
.woocommerce #respond input#submit.alt:hover {
  background-color: var(--global-palette2);
  border-color: var(--global-palette2);
  color: var(--global-palette1); }

.woocommerce a.button.alt.wc-forward:hover,
.woocommerce button.button.alt.wc-forward:hover,
.woocommerce input.button.alt.wc-forward:hover,
.woocommerce #respond input#submit.alt.wc-forward:hover {
  background-color: var(--global-palette-btn-bg-hover);
  border-color: var(--global-palette-btn-bg-hover);
  color: var(--global-palette-btn-hover); }

.woocommerce a.button.alt.disabled, .woocommerce a.button.alt:disabled, .woocommerce a.button.alt:disabled[disabled], .woocommerce a.button.alt.disabled:hover, .woocommerce a.button.alt:disabled:hover, .woocommerce a.button.alt:disabled[disabled]:hover,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt:disabled[disabled],
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled[disabled]:hover,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt:disabled[disabled],
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled[disabled]:hover,
.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt:disabled[disabled],
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt:disabled[disabled]:hover {
  background-color: var(--global-palette-btn-bg);
  color: var(--global-palette-btn);
  border-color: var(--global-palette-btn-bg); }

.woocommerce a.button:disabled, .woocommerce a.button.disabled, .woocommerce a.button:disabled[disabled],
.woocommerce button.button:disabled,
.woocommerce button.button.disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button:disabled,
.woocommerce input.button.disabled,
.woocommerce input.button:disabled[disabled],
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled[disabled] {
  cursor: not-allowed;
  opacity: 0.5; }

.woocommerce .cart .button,
.woocommerce .cart input.button {
  float: none; }

.woocommerce #reviews h2 small {
  float: right;
  color: var(--global-palette6);
  font-size: 15px;
  margin: 10px 0 0; }

.woocommerce #reviews h2 small a {
  text-decoration: none;
  color: var(--global-palette6); }

.woocommerce #reviews h3 {
  margin: 0; }

.woocommerce #reviews #respond {
  margin: 0;
  border: 0;
  padding: 0; }

.woocommerce #reviews #respond .comment-form-rating p {
  margin: 1em 0; }

.woocommerce #reviews #comment {
  height: 75px; }

.woocommerce #reviews .comment-reply-title {
  margin-block-end: 0;
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold; }

.woocommerce #reviews #comments .add_review {
  *zoom: 1; }

.woocommerce #reviews #comments .add_review::before, .woocommerce #reviews #comments .add_review::after {
  content: ' ';
  display: table; }

.woocommerce #reviews #comments .add_review::after {
  clear: both; }

.woocommerce #reviews #comments h2 {
  font-weight: bold;
  margin-block-end: 0.5em;
  font-size: 24px;
  line-height: 1.5; }

.woocommerce #reviews #comments ol.commentlist {
  *zoom: 1;
  width: 100%;
  background: none;
  list-style: none;
  padding-inline-start: 0;
  margin-inline-start: 0; }

.woocommerce #reviews #comments ol.commentlist::before, .woocommerce #reviews #comments ol.commentlist::after {
  content: ' ';
  display: table; }

.woocommerce #reviews #comments ol.commentlist::after {
  clear: both; }

.woocommerce #reviews #comments ol.commentlist li {
  padding: 0;
  margin: 0 0 20px;
  border: 0;
  position: relative;
  background: 0;
  border: 0; }

.woocommerce #reviews #comments ol.commentlist li img.avatar {
  float: left;
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: 0;
  box-shadow: none;
  width: 50px;
  height: 50px;
  border-radius: 50px; }

.woocommerce #reviews #comments ol.commentlist li .comment-text {
  margin: 0 0 0 70px;
  border: 1px solid var(--global-gray-400);
  padding: 1em 1em 0;
  *zoom: 1; }

.woocommerce #reviews #comments ol.commentlist li .comment-text::before, .woocommerce #reviews #comments ol.commentlist li .comment-text::after {
  content: ' ';
  display: table; }

.woocommerce #reviews #comments ol.commentlist li .comment-text::after {
  clear: both; }

.woocommerce #reviews #comments ol.commentlist li .comment-text p {
  margin: 0 0 1.5em; }

.woocommerce #reviews #comments ol.commentlist ul.children {
  list-style: none outside;
  margin: 20px 0 0 50px; }

.woocommerce #reviews #comments ol.commentlist ul.children .star-rating {
  display: none; }

.woocommerce #reviews #comments ol.commentlist #respond {
  border: 1px solid var(--global-gray-400);
  padding: 1em 1em 0;
  margin: 20px 0 0 50px; }

.woocommerce #reviews #comments .commentlist > li::before {
  content: ""; }

.woocommerce #review_form_wrapper {
  margin-block-start: var(--global-md-spacing); }

@media screen and (min-width: 1025px) {
  .woocommerce #reviews #comments {
    float: left;
    width: 60%;
    padding-inline-end: var(--global-lg-spacing); }
  .woocommerce #reviews #review_form_wrapper {
    float: left;
    width: 40%;
    margin-block-start: 0; }
  .woocommerce.rtl #reviews #comments {
    float: right;
    padding-inline-start: var(--global-lg-spacing);
    padding-inline-end: 0; }
  .woocommerce.rtl #reviews #review_form_wrapper {
    float: right; } }

.woocommerce .star-rating {
  display: block;
  float: none;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: .9;
  font-size: 1em;
  width: 5em;
  font-family: "star";
  color: #e0e0e0;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  margin: .1em 0; }

.woocommerce .star-rating::before {
  content: "\e901\e901\e901\e901\e901";
  float: left;
  top: 0;
  left: 0;
  position: absolute; }

.woocommerce .star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-block-start: 1.5em;
  color: #ffc600; }

.woocommerce .star-rating span::before {
  content: "\e901\e901\e901\e901\e901";
  top: 0;
  position: absolute;
  left: 0; }

.woocommerce .woocommerce-product-rating {
  *zoom: 1;
  line-height: 2;
  display: block; }

.woocommerce .woocommerce-product-rating::before, .woocommerce .woocommerce-product-rating::after {
  content: ' ';
  display: table; }

.woocommerce .woocommerce-product-rating::after {
  clear: both; }

.woocommerce .woocommerce-product-rating .star-rating {
  margin: 0.5em 4px 0 0;
  float: left; }

.woocommerce .hreview-aggregate .star-rating {
  margin: 10px 0 0; }

.woocommerce #review_form #respond {
  *zoom: 1;
  position: static;
  margin: 0;
  width: auto;
  padding: 0;
  background: transparent none;
  border: 0; }

.woocommerce #review_form #respond::before, .woocommerce #review_form #respond::after {
  content: ' ';
  display: table; }

.woocommerce #review_form #respond::after {
  clear: both; }

.woocommerce #review_form #respond p {
  margin: 1em 0;
  display: block; }

.woocommerce #review_form #respond .form-submit input {
  left: auto; }

.woocommerce #review_form #respond textarea {
  box-sizing: border-box;
  width: 100%; }

.woocommerce p.stars a {
  position: relative;
  height: 1em;
  width: 1em;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
  color: #ffd000;
  font-size: 20px; }

.woocommerce p.stars a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  line-height: 1;
  content: "\e900";
  text-indent: 0;
  font-family: "star"; }

.woocommerce p.stars a:hover ~ a::before {
  content: "\e900"; }

.woocommerce p.stars:hover a::before {
  content: "\e901"; }

.woocommerce p.stars.selected a.active::before {
  content: "\e901"; }

.woocommerce p.stars.selected a.active ~ a::before {
  content: "\e900"; }

.woocommerce p.stars.selected a:not(.active)::before {
  content: "\e901"; }

.woocommerce table.shop_attributes {
  border: 1px solid var(--global-gray-400);
  margin-bottom: 1.618em;
  width: 100%;
  border-collapse: separate; }

.woocommerce table.shop_attributes th {
  width: 160px;
  font-weight: 400;
  padding: 12px;
  border: none;
  margin: 0;
  line-height: 1.6;
  font-size: 16px;
  background: var(--global-palette7); }

.woocommerce table.shop_attributes td {
  padding: 12px;
  border: none;
  margin: 0;
  line-height: 1.5;
  background: var(--global-palette7); }

.woocommerce table.shop_attributes td p {
  margin: 0;
  padding: 0; }

.woocommerce table.shop_attributes tr:nth-child(even) td,
.woocommerce table.shop_attributes tr:nth-child(even) th {
  background: var(--global-palette7); }

.woocommerce table.shop_table {
  margin: 0 -1px 1.5em 0;
  text-align: left;
  width: 100%;
  border-collapse: collapse; }

.woocommerce table.shop_table input {
  font-size: 100%; }

.woocommerce table.shop_table th {
  font-weight: 500;
  padding: 9px 12px;
  line-height: 1.5em; }

.woocommerce table.shop_table td {
  border-top: 1px solid var(--global-gray-400);
  padding: 9px 12px;
  vertical-align: middle;
  line-height: 1.5em;
  font-weight: initial; }

.woocommerce table.shop_table td small {
  font-weight: normal; }

.woocommerce table.shop_table td del {
  font-weight: normal;
  opacity: .5; }

.woocommerce table.shop_table tbody:first-child tr:first-child th,
.woocommerce table.shop_table tbody:first-child tr:first-child td {
  border-top: 0; }

.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th,
.woocommerce table.shop_table tbody th {
  font-weight: 700;
  border-top: 1px solid var(--global-gray-400); }

.woocommerce table.shop_table tfoot td span,
.woocommerce table.shop_table tfoot th span,
.woocommerce table.shop_table tbody th span {
  font-weight: initial; }

.woocommerce table.my_account_orders {
  font-size: 0.85em; }

.woocommerce table.my_account_orders th,
.woocommerce table.my_account_orders td {
  padding: 4px 8px;
  vertical-align: middle; }

.woocommerce table.my_account_orders .button {
  white-space: nowrap; }

.woocommerce table.woocommerce-MyAccount-downloads td,
.woocommerce table.woocommerce-MyAccount-downloads th {
  vertical-align: top;
  text-align: center; }

.woocommerce table.woocommerce-MyAccount-downloads td:first-child,
.woocommerce table.woocommerce-MyAccount-downloads th:first-child {
  text-align: left; }

.woocommerce table.woocommerce-MyAccount-downloads td:last-child,
.woocommerce table.woocommerce-MyAccount-downloads th:last-child {
  text-align: left; }

.woocommerce td.product-name dl.variation,
.woocommerce td.product-name .wc-item-meta {
  list-style: none outside; }

.woocommerce td.product-name dl.variation dt,
.woocommerce td.product-name dl.variation .wc-item-meta-label,
.woocommerce td.product-name .wc-item-meta dt,
.woocommerce td.product-name .wc-item-meta .wc-item-meta-label {
  float: left;
  clear: both;
  margin-inline-end: 0.25em;
  display: inline-block;
  list-style: none outside; }

.woocommerce td.product-name dl.variation dd,
.woocommerce td.product-name .wc-item-meta dd {
  margin: 0; }

.woocommerce td.product-name dl.variation p, .woocommerce td.product-name dl.variation:last-child,
.woocommerce td.product-name .wc-item-meta p,
.woocommerce td.product-name .wc-item-meta:last-child {
  margin-block-end: 0; }

.woocommerce td.product-name p.backorder_notification {
  font-size: 0.83em; }

.woocommerce td.product-quantity {
  min-width: 80px; }

.woocommerce ul.cart_list,
.woocommerce ul.product_list_widget {
  list-style: none outside;
  padding: 0;
  margin: 0; }

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li {
  padding: 4px 0;
  margin: 0;
  *zoom: 1;
  list-style: none; }

.woocommerce ul.cart_list li::before, .woocommerce ul.cart_list li::after,
.woocommerce ul.product_list_widget li::before,
.woocommerce ul.product_list_widget li::after {
  content: ' ';
  display: table; }

.woocommerce ul.cart_list li::after,
.woocommerce ul.product_list_widget li::after {
  clear: both; }

.woocommerce ul.cart_list li .quantity,
.woocommerce ul.product_list_widget li .quantity {
  display: block;
  padding-left: 75px; }

.woocommerce ul.cart_list li a,
.woocommerce ul.product_list_widget li a {
  display: block;
  text-decoration: none; }

.woocommerce ul.cart_list li a:hover,
.woocommerce ul.product_list_widget li a:hover {
  text-decoration: underline; }

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img {
  float: left;
  margin-inline-end: 8px;
  width: 64px;
  height: auto;
  box-shadow: none; }

.woocommerce ul.cart_list li dl,
.woocommerce ul.product_list_widget li dl {
  margin: 0;
  padding-inline-start: 1em;
  border-left: 2px solid var(--global-gray-400);
  *zoom: 1; }

.woocommerce ul.cart_list li dl::before, .woocommerce ul.cart_list li dl::after,
.woocommerce ul.product_list_widget li dl::before,
.woocommerce ul.product_list_widget li dl::after {
  content: ' ';
  display: table; }

.woocommerce ul.cart_list li dl::after,
.woocommerce ul.product_list_widget li dl::after {
  clear: both; }

.woocommerce ul.cart_list li dl dt,
.woocommerce ul.cart_list li dl dd,
.woocommerce ul.product_list_widget li dl dt,
.woocommerce ul.product_list_widget li dl dd {
  display: inline-block;
  float: left;
  margin: 0 0 1em 0; }

.woocommerce ul.cart_list li dl dt,
.woocommerce ul.product_list_widget li dl dt {
  font-weight: 700;
  padding: 0 0 0.25em;
  margin: 0 4px 0 0;
  clear: left; }

.woocommerce ul.cart_list li dl dd,
.woocommerce ul.product_list_widget li dl dd {
  padding: 0 0 0.25em;
  margin: 0; }

.woocommerce ul.cart_list li dl dd p,
.woocommerce ul.product_list_widget li dl dd p {
  margin-block-start: 0; }

.woocommerce ul.cart_list li dl dd p:last-child,
.woocommerce ul.product_list_widget li dl dd p:last-child {
  margin-block-end: 0; }

.woocommerce ul.cart_list li dl.variation,
.woocommerce ul.product_list_widget li dl.variation {
  padding-inline-start: 58px;
  font-size: 0.8em;
  border: 0; }

.woocommerce ul.cart_list li .star-rating,
.woocommerce ul.product_list_widget li .star-rating {
  float: none; }

.woocommerce ul.cart_list li.mini_cart_item dl dt {
  clear: none; }

.woocommerce.widget_shopping_cart .buttons a.button,
.woocommerce .widget_shopping_cart .buttons a.button {
  background: var(--global-palette-btn-bg-hover);
  color: var(--global-palette-btn-hover);
  border-color: var(--global-palette-btn-bg-hover); }

.woocommerce.widget_shopping_cart .buttons a.button:hover,
.woocommerce .widget_shopping_cart .buttons a.button:hover {
  background: var(--global-palette-btn-bg-hover);
  color: var(--global-palette-btn-hover);
  border-color: var(--global-palette-btn-bg-hover); }

.woocommerce.widget_shopping_cart .total,
.woocommerce .widget_shopping_cart .total {
  border-top: 1px solid var(--global-palette7);
  padding: 20px;
  display: flex;
  margin-block-start: 0;
  justify-content: space-between; }

.woocommerce.widget_shopping_cart .total strong,
.woocommerce .widget_shopping_cart .total strong {
  min-width: 40px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: var(--global-palette1); }

.woocommerce.widget_shopping_cart .cart_list li,
.woocommerce .widget_shopping_cart .cart_list li {
  padding-inline-start: 0em;
  padding-inline-end: 24px;
  position: relative;
  padding-block-start: 0;
  padding-block-end: 4px;
  margin-block-end: 4px;
  border-bottom: 1px solid var(--global-palette7); }

.woocommerce.widget_shopping_cart .cart_list li:last-child,
.woocommerce .widget_shopping_cart .cart_list li:last-child {
  border-bottom: 0;
  margin-block-end: 0; }

.woocommerce.widget_shopping_cart .cart_list li a.remove,
.woocommerce .widget_shopping_cart .cart_list li a.remove {
  position: absolute;
  top: 12px;
  right: 0;
  font-size: 16px;
  font-weight: normal;
  height: 18px;
  width: 18px;
  line-height: 14px;
  text-decoration: none; }

.woocommerce.widget_shopping_cart .cart_list li a.remove:hover,
.woocommerce .widget_shopping_cart .cart_list li a.remove:hover {
  text-decoration: none; }

.woocommerce.widget_shopping_cart .buttons,
.woocommerce .widget_shopping_cart .buttons {
  display: flex;
  flex-direction: column;
  text-align: center;
  *zoom: 1; }

.woocommerce.widget_shopping_cart .buttons::before, .woocommerce.widget_shopping_cart .buttons::after,
.woocommerce .widget_shopping_cart .buttons::before,
.woocommerce .widget_shopping_cart .buttons::after {
  content: ' ';
  display: table; }

.woocommerce.widget_shopping_cart .buttons::after,
.woocommerce .widget_shopping_cart .buttons::after {
  clear: both; }

.woocommerce.widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons a {
  margin-bottom: 5px; }

.woocommerce ul.order_details {
  *zoom: 1;
  margin: 0 0 3em;
  padding: 0;
  list-style: none; }

.woocommerce ul.order_details::before, .woocommerce ul.order_details::after {
  content: ' ';
  display: table; }

.woocommerce ul.order_details::after {
  clear: both; }

.woocommerce ul.order_details li {
  float: left;
  margin-inline-end: 2em;
  text-transform: uppercase;
  font-size: 0.715em;
  line-height: 1;
  border-right: 1px dashed var(--global-gray-400);
  padding-inline-end: 2em;
  margin-inline-start: 0;
  padding-inline-start: 0;
  list-style-type: none; }

.woocommerce ul.order_details li strong {
  display: block;
  font-size: 1.4em;
  text-transform: none;
  line-height: 1.5; }

.woocommerce ul.order_details li:last-of-type {
  border: none; }

.woocommerce ul.woocommerce-order-overview.woocommerce-thankyou-order-details {
  margin: 1.5em 0; }

.woocommerce .wc-bacs-bank-details-account-name {
  font-weight: bold; }

.woocommerce .woocommerce-order-downloads,
.woocommerce .woocommerce-customer-details,
.woocommerce .woocommerce-order-details {
  margin-block-end: 2em; }

.woocommerce .woocommerce-order-downloads *:last-child,
.woocommerce .woocommerce-customer-details *:last-child,
.woocommerce .woocommerce-order-details *:last-child {
  margin-block-end: 0; }

.woocommerce .woocommerce-customer-details address {
  font-style: normal;
  margin-block-end: 0;
  border: 1px solid var(--global-gray-400);
  text-align: left;
  width: 100%;
  border-radius: 5px;
  padding: 6px 12px; }

.woocommerce .woocommerce-customer-details address p {
  margin: 0; }

.woocommerce .woocommerce-customer-details .woocommerce-customer-details--phone,
.woocommerce .woocommerce-customer-details .woocommerce-customer-details--email {
  margin-block-end: 0;
  margin-block-start: 0;
  padding-inline-start: 0; }

.woocommerce .woocommerce-widget-layered-nav-list {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none outside; }

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item {
  vertical-align: middle;
  *zoom: 1;
  list-style: none; }

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::before, .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after {
  content: ' ';
  display: table; }

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after {
  clear: both; }

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span {
  text-decoration: none;
  vertical-align: middle; }

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item .count {
  font-size: 80%; }

.woocommerce .woocommerce-widget-layered-nav-list a::before {
  content: "";
  position: relative;
  top: -0.15em;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-inline-end: 0.6em;
  font-size: .5em;
  line-height: 1.3;
  text-align: center;
  vertical-align: middle;
  border: 1px solid currentColor;
  opacity: 0.4; }

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a::before {
  content: '\2713';
  color: #000;
  opacity: 1;
  border-color: var(--global-palette3);
  background: var(--global-palette9); }

.woocommerce .woocommerce-widget-layered-nav-dropdown__submit {
  margin-block-start: 1em; }

.woocommerce .widget_layered_nav_filters ul {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none outside;
  overflow: hidden;
  zoom: 1; }

.woocommerce .widget_layered_nav_filters ul li {
  float: left;
  padding: 0 1em 1px 1px;
  list-style: none; }

.woocommerce .widget_layered_nav_filters ul li a {
  text-decoration: none;
  background: var(--global-palette9);
  color: var(--global-palette5);
  padding: 0;
  transition: all 0.3s ease-in-out; }

.woocommerce .widget_layered_nav_filters ul li a:hover, .woocommerce .widget_layered_nav_filters ul li a:focus {
  text-decoration: none;
  color: initial; }

.woocommerce .widget_layered_nav_filters ul li a:hover::before, .woocommerce .widget_layered_nav_filters ul li a:focus::before {
  color: #a00;
  content: "\2716"; }

.woocommerce .widget_layered_nav_filters ul li a::before {
  font-family: 'serif';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-inline-end: 0.618em;
  content: "✕";
  text-decoration: none;
  unicode-bidi: bidi-override;
  vertical-align: middle;
  margin-inline-end: 0.5em; }

.woocommerce.widget_product_categories > ul, .woocommerce .wp-block-woocommerce-product-categories > ul {
  margin-inline-start: 0;
  list-style: none; }

.woocommerce.widget_product_categories > ul li, .woocommerce .wp-block-woocommerce-product-categories > ul li {
  position: relative; }

.woocommerce.widget_product_categories > ul li .count, .woocommerce.widget_product_categories > ul li .wc-block-product-categories-list-item-count, .woocommerce .wp-block-woocommerce-product-categories > ul li .count, .woocommerce .wp-block-woocommerce-product-categories > ul li .wc-block-product-categories-list-item-count {
  position: absolute;
  right: 0;
  display: block;
  top: 3px;
  min-width: 2rem;
  text-align: center;
  font-size: 80%; }

.woocommerce.widget_product_categories > ul li .count ~ .thebase-cat-toggle-sub, .woocommerce .wp-block-woocommerce-product-categories > ul li .count ~ .thebase-cat-toggle-sub {
  right: 1.5rem; }

.woocommerce.widget_product_categories > ul li a, .woocommerce .wp-block-woocommerce-product-categories > ul li a {
  display: block; }

.woocommerce.widget_product_categories > ul li a:hover, .woocommerce .wp-block-woocommerce-product-categories > ul li a:hover {
  text-decoration: none; }

.woocommerce.widget_product_categories > ul li.current-cat > a, .woocommerce .wp-block-woocommerce-product-categories > ul li.current-cat > a {
  color: var(--global-palette2); }

.woocommerce.widget_product_categories > ul li.cat-parent > ul, .woocommerce .wp-block-woocommerce-product-categories > ul li.cat-parent > ul {
  display: none; }

.woocommerce.widget_product_categories > ul li.cat-parent.sub-toggle-active > ul, .woocommerce .wp-block-woocommerce-product-categories > ul li.cat-parent.sub-toggle-active > ul {
  display: block; }

.woocommerce.widget_product_categories > ul li.cat-parent.sub-toggle-active > .thebase-cat-toggle-sub:before, .woocommerce .wp-block-woocommerce-product-categories > ul li.cat-parent.sub-toggle-active > .thebase-cat-toggle-sub:before {
  margin-block-start: -1px;
  transform: rotate(-45deg);
  margin-inline-start: -3px; }

.woocommerce.widget_product_categories > ul li .thebase-cat-toggle-sub, .woocommerce .wp-block-woocommerce-product-categories > ul li .thebase-cat-toggle-sub {
  display: block;
  background: transparent;
  width: 2em;
  text-align: center;
  position: absolute;
  right: 0;
  top: 2px;
  line-height: 2;
  color: var(--global-palette4);
  border: 0;
  height: 2em;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer; }

.woocommerce.widget_product_categories > ul li .thebase-cat-toggle-sub:before, .woocommerce .wp-block-woocommerce-product-categories > ul li .thebase-cat-toggle-sub:before {
  display: block;
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 8px;
  top: 50%;
  left: 50%;
  margin-inline-start: -2px;
  margin-block-start: -4px;
  transform: rotate(135deg);
  width: 8px;
  position: absolute;
  font-weight: normal; }

.woocommerce.widget_price_filter .price_slider {
  margin-bottom: 1em; }

.woocommerce.widget_price_filter .price_slider_amount {
  text-align: right;
  line-height: 2.4;
  font-size: 16px;
  font-weight: 400;
  color: var(--global-palette5); }

.woocommerce.widget_price_filter .price_slider_amount .button {
  float: left; }

.woocommerce.widget_price_filter .ui-slider {
  position: relative;
  text-align: left;
  margin-inline-start: 0.5em;
  margin-inline-end: 0.5em; }

.woocommerce.widget_price_filter .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1em;
  height: 1em;
  background-color: var(--global-palette-highlight-alt);
  border-radius: 1em;
  cursor: ew-resize;
  outline: none;
  top: -0.3em;
  /* rtl:ignore */
  margin-inline-start: -0.5em; }

.woocommerce.widget_price_filter .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  border-radius: 1em;
  background-color: var(--global-gray-400); }

.woocommerce.widget_price_filter .price_slider_wrapper .ui-widget-content {
  border-radius: 1em;
  background-color: var(--global-palette7);
  border: 0; }

.woocommerce.widget_price_filter .ui-slider-horizontal {
  height: 0.4em; }

.woocommerce.widget_price_filter .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%; }

.woocommerce.widget_price_filter .ui-slider-horizontal .ui-slider-range-min {
  left: -1px; }

.woocommerce.widget_price_filter .ui-slider-horizontal .ui-slider-range-max {
  right: -1px; }

.woocommerce.widget_rating_filter ul {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none outside; }

.woocommerce.widget_rating_filter ul li {
  *zoom: 1;
  padding: 0 0 1px;
  list-style: none; }

.woocommerce.widget_rating_filter ul li::before, .woocommerce.widget_rating_filter ul li::after {
  content: ' ';
  display: table; }

.woocommerce.widget_rating_filter ul li::after {
  clear: both; }

.woocommerce.widget_rating_filter ul li a {
  padding: 1px 0;
  text-decoration: none;
  font-size: 80%; }

.woocommerce.widget_rating_filter ul li a:hover, .woocommerce.widget_rating_filter ul li a:focus {
  text-decoration: none; }

.woocommerce.widget_rating_filter ul li a:before {
  content: "";
  position: relative;
  top: -0.15em;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-inline-end: 0.6em;
  font-size: .5rem;
  line-height: 1.4;
  text-align: center;
  vertical-align: middle;
  border: 1px solid currentColor;
  border-radius: 0.25rem;
  opacity: 0.4; }

.woocommerce.widget_rating_filter ul li .star-rating {
  float: none;
  margin-block-end: -0.1em;
  font-size: 1rem;
  display: inline-block; }

.woocommerce.widget_rating_filter ul li.chosen a::before {
  content: '\2713';
  color: white;
  opacity: 1;
  border-color: var(--global-palette-highlight);
  background: var(--global-palette-highlight); }

.woocommerce .woocommerce-form-login .woocommerce-form-login__submit {
  float: left;
  margin-inline-end: 1em; }

.woocommerce .woocommerce-form-login .woocommerce-form-login__rememberme {
  display: inline-block; }

.rtl #cart-drawer dl.variation {
  display: flex; }

.rtl .woocommerce ul.product_list_widget li dl dd {
  margin: 0 4px 0 0; }

.rtl .woocommerce ul.product_list_widget li dl dt {
  margin: 0; }

.rtl .woocommerce .woocommerce-customer-details address {
  text-align: right; }

.rtl .woocommerce ul.order_details li {
  float: right;
  margin-inline-end: 0;
  padding-inline-start: 2em;
  margin-inline-end: 2em;
  padding-inline-end: 0;
  border-left: 1px dashed var(--global-gray-400);
  border-right: 0; }

.rtl .woocommerce ul.order_details li:last-of-type {
  border: none; }

.woocommerce-no-js form.woocommerce-form-login,
.woocommerce-no-js form.woocommerce-form-coupon {
  display: block !important; }

.woocommerce-no-js .woocommerce-form-login-toggle,
.woocommerce-no-js .woocommerce-form-coupon-toggle,
.woocommerce-no-js .showcoupon {
  display: none !important; }

.woocommerce .woocommerce-message,
.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info {
  padding: 1em;
  margin: 0 0 var(--global-md-spacing);
  position: relative;
  background-color: var(--global-palette9);
  color: var(--global-palette4);
  list-style: none outside;
  *zoom: 1;
  width: auto;
  word-wrap: break-word;
  border: 1px solid var(--global-gray-400);
  border-inline-start-width: 4px;
  *zoom: 1;
  width: auto;
  word-wrap: break-word; }

.woocommerce .woocommerce-message::before, .woocommerce .woocommerce-message::after,
.woocommerce .woocommerce-error::before,
.woocommerce .woocommerce-error::after,
.woocommerce .woocommerce-info::before,
.woocommerce .woocommerce-info::after {
  content: ' ';
  display: table; }

.woocommerce .woocommerce-message::after,
.woocommerce .woocommerce-error::after,
.woocommerce .woocommerce-info::after {
  clear: both; }

.woocommerce .woocommerce-message .button,
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button {
  float: right;
  margin-block-start: -0.7em;
  margin-block-end: -.7em; }

.woocommerce .woocommerce-message li,
.woocommerce .woocommerce-error li,
.woocommerce .woocommerce-info li {
  list-style: none outside !important;
  padding-inline-start: 0 !important;
  margin-inline-start: 0 !important; }

.woocommerce .woocommerce-message {
  border-inline-start-color: var(--global-palette1); }

.woocommerce .woocommerce-info {
  border-inline-start-color: var(--global-palette1); }

.woocommerce .woocommerce-error {
  border-inline-start-color: #f53311; }

.woocommerce-checkout .woocommerce-form-login-toggle .woocommerce-info,
.woocommerce-checkout .woocommerce-form-coupon-toggle .woocommerce-info {
  background: none;
  padding: 0;
  border: 0; }

.woocommerce-checkout .woocommerce-form-login-toggle .woocommerce-info .showcoupon,
.woocommerce-checkout .woocommerce-form-login-toggle .woocommerce-info .showlogin,
.woocommerce-checkout .woocommerce-form-coupon-toggle .woocommerce-info .showcoupon,
.woocommerce-checkout .woocommerce-form-coupon-toggle .woocommerce-info .showlogin {
  color: var(--global-palette1); }

.woocommerce-checkout .woocommerce-form-login-toggle .woocommerce-info {
  margin: 0 0 var(--global-xs-spacing); }

/**
  * Right to left styles
  */
.rtl.woocommerce .price_label,
.rtl.woocommerce .price_label span {
  /* rtl:ignore */
  direction: ltr;
  unicode-bidi: embed; }

/**
  * Password strength meter
  */
.woocommerce-password-strength {
  text-align: center;
  font-weight: 600;
  padding: 3px 0.5em;
  font-size: 1em; }

.woocommerce-password-strength.strong {
  background-color: #c1e1b9;
  border-color: #83c373; }

.woocommerce-password-strength.short {
  background-color: #f1adad;
  border-color: #e35b5b; }

.woocommerce-password-strength.bad {
  background-color: #fbc5a9;
  border-color: #f78b53; }

.woocommerce-password-strength.good {
  background-color: #ffe399;
  border-color: #ffc733; }

.woocommerce-privacy-policy-text a, .woocommerce-privacy-policy-text a:hover,
.woocommerce-page .woocommerce-MyAccount-content a,
.woocommerce-page .woocommerce-MyAccount-content a:hover,
.woocommerce-page .place-order a,
.woocommerce-page .place-order a:hover,
.woocommerce-page .woocommerce-info a,
.woocommerce-page .woocommerce-info a:hover {
  text-decoration: underline; }

.woocommerce-password-hint {
  margin: 0.5em 0 0;
  display: block; }

body.showing-filter-drawer .select2-container {
  z-index: 100000; }

/**
 * woocommerce-smallscreen.scss
 * Optimises the default WooCommerce frontend layout when viewed on smaller screens.
 */
/**
 * Imports
 */
/**
 * Custom Properties
 */
/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
/**
 * Objects
 */
/**
  * Style begins
  */
@media screen and (max-width: 719px) {
  .woocommerce div.product .woocommerce-tabs {
    border: 1px solid var(--global-gray-400);
    margin-block-end: 2em; }
  .woocommerce div.product .woocommerce-tabs ul.tabs {
    padding: 0;
    margin-block-end: 1em; }
  .woocommerce div.product .woocommerce-tabs ul.tabs::before {
    border: 0; }
  .woocommerce div.product .woocommerce-tabs ul.tabs li {
    display: block;
    margin: 0;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid var(--global-gray-400);
    text-align: center; }
  .woocommerce div.product .woocommerce-tabs ul.tabs li a {
    display: block; }
  .woocommerce div.product .woocommerce-tabs ul.tabs li.active {
    border-bottom-color: var(--global-gray-400); }
  .woocommerce div.product .woocommerce-tabs ul.tabs li.active a {
    opacity: 1; }
  .woocommerce div.product .woocommerce-tabs .panel {
    padding-inline-start: 1em;
    padding-inline-end: 1em;
    margin: 0; }
  .woocommerce.product-tab-style-center div.product .woocommerce-tabs ul.tabs li {
    border: 0;
    border-bottom: 1px solid var(--global-gray-400); }
  .woocommerce #reviews #comments ol.commentlist li img.avatar {
    width: 25px;
    height: 25px; }
  .woocommerce #reviews #comments ol.commentlist li .comment-text {
    margin-inline-start: 45px; }
  .woocommerce,
  .woocommerce-page {
    /**
      * General layout
      */
    /**
      * Product Details
      */
    /**
      * Cart
      */
    /**
      * Checkout
      */
    /**
      * Account
      */ }
  .woocommerce table.shop_table_responsive ul#shipping_method li,
  .woocommerce-page table.shop_table_responsive ul#shipping_method li {
    justify-content: flex-end; }
  .woocommerce table.shop_table_responsive thead,
  .woocommerce-page table.shop_table_responsive thead {
    display: none; }
  .woocommerce table.shop_table_responsive tbody tr:first-child td:first-child,
  .woocommerce-page table.shop_table_responsive tbody tr:first-child td:first-child {
    border-top: 0; }
  .woocommerce table.shop_table_responsive tbody th,
  .woocommerce-page table.shop_table_responsive tbody th {
    display: none; }
  .woocommerce table.shop_table_responsive tr,
  .woocommerce-page table.shop_table_responsive tr {
    display: block; }
  .woocommerce table.shop_table_responsive tr td,
  .woocommerce-page table.shop_table_responsive tr td {
    display: block;
    text-align: right !important; }
  .woocommerce table.shop_table_responsive tr td.order-actions,
  .woocommerce-page table.shop_table_responsive tr td.order-actions {
    text-align: left !important; }
  .woocommerce table.shop_table_responsive tr td::before,
  .woocommerce-page table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 700;
    float: left; }
  .woocommerce table.shop_table_responsive tr td.product-remove::before, .woocommerce table.shop_table_responsive tr td.actions::before,
  .woocommerce-page table.shop_table_responsive tr td.product-remove::before,
  .woocommerce-page table.shop_table_responsive tr td.actions::before {
    display: none; }
  .woocommerce table.shop_table_responsive tr:nth-child(2n) td,
  .woocommerce-page table.shop_table_responsive tr:nth-child(2n) td {
    background-color: rgba(0, 0, 0, 0.025); }
  .woocommerce table.my_account_orders tr td.order-actions,
  .woocommerce-page table.my_account_orders tr td.order-actions {
    text-align: left; }
  .woocommerce table.my_account_orders tr td.order-actions::before,
  .woocommerce-page table.my_account_orders tr td.order-actions::before {
    display: none; }
  .woocommerce table.my_account_orders tr td.order-actions .button,
  .woocommerce-page table.my_account_orders tr td.order-actions .button {
    float: none;
    margin: 0.125em 0.25em 0.125em 0; }
  .woocommerce .col2-set .col-1,
  .woocommerce .col2-set .col-2,
  .woocommerce-page .col2-set .col-1,
  .woocommerce-page .col2-set .col-2 {
    float: none;
    width: 100%; }
  .woocommerce .col2-set .col-2,
  .woocommerce-page .col2-set .col-2 {
    margin-block-start: var(--global-md-spacing); }
  .woocommerce #primary div.product div.images,
  .woocommerce #primary div.product div.summary,
  .woocommerce #primary #content div.product div.images,
  .woocommerce #primary #content div.product div.summary,
  .woocommerce-page #primary div.product div.images,
  .woocommerce-page #primary div.product div.summary,
  .woocommerce-page #primary #content div.product div.images,
  .woocommerce-page #primary #content div.product div.summary {
    float: none;
    width: 100%; }
  .woocommerce table.cart .product-thumbnail,
  .woocommerce #content table.cart .product-thumbnail,
  .woocommerce-page table.cart .product-thumbnail,
  .woocommerce-page #content table.cart .product-thumbnail {
    border-top: 0; }
  .woocommerce table.cart .product-thumbnail:before,
  .woocommerce #content table.cart .product-thumbnail:before,
  .woocommerce-page table.cart .product-thumbnail:before,
  .woocommerce-page #content table.cart .product-thumbnail:before {
    display: none; }
  .woocommerce table.cart .product-thumbnail img,
  .woocommerce #content table.cart .product-thumbnail img,
  .woocommerce-page table.cart .product-thumbnail img,
  .woocommerce-page #content table.cart .product-thumbnail img {
    margin-inline-start: auto;
    margin-inline-end: auto; }
  .woocommerce table.cart .product-remove,
  .woocommerce #content table.cart .product-remove,
  .woocommerce-page table.cart .product-remove,
  .woocommerce-page #content table.cart .product-remove {
    padding-block-end: 0; }
  .woocommerce table.cart .product-remove .remove,
  .woocommerce #content table.cart .product-remove .remove,
  .woocommerce-page table.cart .product-remove .remove,
  .woocommerce-page #content table.cart .product-remove .remove {
    transform: translateY(10px); }
  .woocommerce table.cart td.actions,
  .woocommerce #content table.cart td.actions,
  .woocommerce-page table.cart td.actions,
  .woocommerce-page #content table.cart td.actions {
    text-align: left;
    padding-inline-start: 0;
    padding-inline-end: 0; }
  .woocommerce table.cart td.actions .coupon,
  .woocommerce #content table.cart td.actions .coupon,
  .woocommerce-page table.cart td.actions .coupon,
  .woocommerce-page #content table.cart td.actions .coupon {
    float: none;
    *zoom: 1;
    padding-block-end: 0.5em; }
  .woocommerce table.cart td.actions .coupon::before, .woocommerce table.cart td.actions .coupon::after,
  .woocommerce #content table.cart td.actions .coupon::before,
  .woocommerce #content table.cart td.actions .coupon::after,
  .woocommerce-page table.cart td.actions .coupon::before,
  .woocommerce-page table.cart td.actions .coupon::after,
  .woocommerce-page #content table.cart td.actions .coupon::before,
  .woocommerce-page #content table.cart td.actions .coupon::after {
    content: ' ';
    display: table; }
  .woocommerce table.cart td.actions .coupon::after,
  .woocommerce #content table.cart td.actions .coupon::after,
  .woocommerce-page table.cart td.actions .coupon::after,
  .woocommerce-page #content table.cart td.actions .coupon::after {
    clear: both; }
  .woocommerce table.cart td.actions .coupon input,
  .woocommerce table.cart td.actions .coupon .button,
  .woocommerce table.cart td.actions .coupon .input-text,
  .woocommerce #content table.cart td.actions .coupon input,
  .woocommerce #content table.cart td.actions .coupon .button,
  .woocommerce #content table.cart td.actions .coupon .input-text,
  .woocommerce-page table.cart td.actions .coupon input,
  .woocommerce-page table.cart td.actions .coupon .button,
  .woocommerce-page table.cart td.actions .coupon .input-text,
  .woocommerce-page #content table.cart td.actions .coupon input,
  .woocommerce-page #content table.cart td.actions .coupon .button,
  .woocommerce-page #content table.cart td.actions .coupon .input-text {
    width: 48%;
    box-sizing: border-box; }
  .woocommerce table.cart td.actions .coupon .input-text + .button,
  .woocommerce table.cart td.actions .coupon .button.alt,
  .woocommerce #content table.cart td.actions .coupon .input-text + .button,
  .woocommerce #content table.cart td.actions .coupon .button.alt,
  .woocommerce-page table.cart td.actions .coupon .input-text + .button,
  .woocommerce-page table.cart td.actions .coupon .button.alt,
  .woocommerce-page #content table.cart td.actions .coupon .input-text + .button,
  .woocommerce-page #content table.cart td.actions .coupon .button.alt {
    float: right; }
  .woocommerce table.cart td.actions .button,
  .woocommerce #content table.cart td.actions .button,
  .woocommerce-page table.cart td.actions .button,
  .woocommerce-page #content table.cart td.actions .button {
    display: block;
    width: 100%; }
  .woocommerce .cart-collaterals .cart_totals,
  .woocommerce .cart-collaterals .shipping_calculator,
  .woocommerce .cart-collaterals .cross-sells,
  .woocommerce-page .cart-collaterals .cart_totals,
  .woocommerce-page .cart-collaterals .shipping_calculator,
  .woocommerce-page .cart-collaterals .cross-sells {
    width: 100%;
    float: none;
    text-align: left; }
  .woocommerce.woocommerce-checkout form.login .form-row,
  .woocommerce-page.woocommerce-checkout form.login .form-row {
    width: 100%;
    float: none; }
  .woocommerce.woocommerce-checkout form .form-row-first, .woocommerce.woocommerce-checkout form .form-row-last,
  .woocommerce-page.woocommerce-checkout form .form-row-first,
  .woocommerce-page.woocommerce-checkout form .form-row-last {
    float: none;
    width: 100%; }
  .woocommerce #payment .terms,
  .woocommerce-page #payment .terms {
    text-align: left;
    padding: 0; }
  .woocommerce .lost_reset_password .form-row-first,
  .woocommerce .lost_reset_password .form-row-last,
  .woocommerce-page .lost_reset_password .form-row-first,
  .woocommerce-page .lost_reset_password .form-row-last {
    width: 100%;
    float: none;
    margin-inline-end: 0; }
  .woocommerce-account .woocommerce-MyAccount-navigation,
  .woocommerce-account .woocommerce-MyAccount-content {
    float: none;
    width: 100%; }
  .rtl .woocommerce table.cart td.actions .coupon .input-text + .button, .rtl .woocommerce table.cart td.actions .coupon .button.alt, .rtl .woocommerce #content table.cart td.actions .coupon .input-text + .button, .rtl .woocommerce #content table.cart td.actions .coupon .button.alt, .rtl .woocommerce-page table.cart td.actions .coupon .input-text + .button, .rtl .woocommerce-page table.cart td.actions .coupon .button.alt, .rtl .woocommerce-page #content table.cart td.actions .coupon .input-text + .button, .rtl .woocommerce-page #content table.cart td.actions .coupon .button.alt {
    float: left; }
  .rtl .woocommerce table.shop_table_responsive tr td, .rtl .woocommerce-page table.shop_table_responsive tr td {
    text-align: left !important; }
  .rtl .woocommerce table.shop_table_responsive tr td::before, .rtl .woocommerce-page table.shop_table_responsive tr td::before {
    float: right; } }

@media screen and (max-width: 540px) {
  .woocommerce .woocommerce-message .button {
    float: none;
    margin-block-start: 0.5em;
    margin-block-end: 0;
    text-align: center; }
  .woocommerce .woocommerce-message {
    display: flex;
    flex-direction: column-reverse; }
  table.cart td.actions .coupon input,
  table.cart td.actions .coupon .button,
  table.cart td.actions .coupon .input-text,
  #content table.cart td.actions .coupon input,
  #content table.cart td.actions .coupon .button,
  #content table.cart td.actions .coupon .input-text {
    width: 100%;
    float: none;
    margin: 0; }
  table.cart td.actions .coupon .input-text + .button,
  table.cart td.actions .coupon .button.alt,
  #content table.cart td.actions .coupon .input-text + .button,
  #content table.cart td.actions .coupon .button.alt {
    float: none; } }

@media screen and (max-width: 400px) {
  .thebase-cart-button-medium-large.woocommerce div.product .button.single_add_to_cart_button, .thebase-cart-button-large.woocommerce div.product .button.single_add_to_cart_button {
    font-size: 100%;
    padding: 0; }
  .woocommerce div.product form.cart .variations td {
    display: block;
    padding-inline-end: 0; } }
