/*--------------------------------------------------------------
# Product Page
--------------------------------------------------------------*/
.product-hero-section .extra-title{
	display: block;
}
.woocommerce.single-product.content-style-boxed .product.entry-content-wrap > span.onsale {
    left: 2.5rem;
    top: 2.5rem;
}
.rtl.woocommerce.single-product.content-style-boxed .product.entry-content-wrap > span.onsale {
    right: 2.5rem;
    left:auto;
}
.woocommerce.single-product.content-style-boxed .products ul.products.woo-archive-action-visible li.product .product-details {
	padding-inline-start: 0;
	padding-inline-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	margin-block-end: 0;
}
.single-post .content-area {
	margin-block-start: 0;
}
.single-product .site div.product form.cart .woocommerce-grouped-product-list-item__quantity .quantity {
    width: 100%;
}
.single-product .site div.product form.cart .woocommerce-grouped-product-list-item__quantity .quantity .qty {
    height: 42px;
}
.woocommerce div.product:after {
    clear: both;
    display: table;
    content: '';
}
// This fixes a CLS
.woocommerce-variation.single_variation {
    display: none;
	.woocommerce-variation-price {
		padding: 10px 0 0;
	}
}
.single.woocommerce {
	table.shop_attributes {
		border: none;
		border-spacing: 10px;
		margin: 0 -10px;
	}
}
.woocommerce {
	.up-sells.products, .related.products {
		clear: both;
	}
	.quantity .qty {
		width: 5em;
		text-align: center;
		padding: 0 0.5em;
		box-sizing: border-box;
		height: 42px;
	}
	.brief-shipping-details {
		font-size: initial;
		color: initial;
		font-weight: initial;
	}
	.woocommerce-product-details__short-description {
		margin-block-end: 2em;
	
	}
	.single-product-extras {
		margin-block: 1em;
		display: inline-block;
		vertical-align: top;
		width: 100%;

		ul {
			padding: 0;
			margin: 0;
			list-style:none;
			li {
				line-height: 1.8em;
			}
			.thebase-svg-iconset {
				padding-inline-end: 0.25em;
			}
		}
	}
	.single-product-payments {
		margin-block-end: 2em;
		display: inline-block;
		margin-block-start: 0;
		padding: 0.625em 1.5em 0.625em 1em;
		border-color: var(--global-gray-400);
		legend {
			padding: 0 0.5em;
			color: var(--global-palette5);
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			flex-wrap:wrap;
		}
		li {
			margin-inline-start:0.5em;
			margin-block-end:0.5em;
		}
		.payment-custom-img {
			max-height: 30px;
			width: auto;
		}
		.payment-custom-img-gray {
			filter: grayscale(1);
		}
		.thebase-svg-iconset svg {
			height: 30px;
			width:auto;
		}
	}
	.single-product-payments.payments-color-scheme-gray {
		legend {
			color: var(--global-palette6);
		}
		.thebase-svg-iconset svg path {
			fill: var(--global-gray-500) !important;
		}
	}
	div.product {
		margin-block-end: 0;
		position: relative;

		.product_title {
			clear: none;
			margin-block-start: 0;
			padding: 0;
		}

		span.price,
		p.price {
			font-size: 1.63em;
			font-weight: 400;
			ins {
				background: inherit;
				display: inline-block;
			}

			del {
				opacity: 0.5;
				display: inline-block;
			}
		}

		.stock {
			color: green;
		}

		.out-of-stock {
			color: red;
		}

		.woocommerce-product-rating {
			margin-block-end: 0.3em;
		}

		div.images {
			margin-block-end: 1em;
			padding-block-end: 1em;
			img {
				display: block;
				width: 100%;
				height: auto;
				box-shadow: none;
			}

			div.thumbnails {
				padding-block-start: 1em;
			}

			&.woocommerce-product-gallery {
				position: relative;
			}

			.woocommerce-product-gallery__wrapper {
				transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
				margin: 0;
				padding: 0;
			}

			.woocommerce-product-gallery__wrapper .zoomImg {
				background-color: #fff;
				opacity: 0;
			}

			.woocommerce-product-gallery__image--placeholder {
				border: 1px solid #f2f2f2;
			}

			.woocommerce-product-gallery__image:nth-child(n+2) {
				display: none;
			}

			.woocommerce-product-gallery__trigger {
				position: absolute;
				top: 0.5rem;
				right: 0.5rem;
				font-size: 2em;
				z-index: 9;
				width: 36px;
				height: 36px;
				background: #fff;
				text-indent: -9999px;
				border-radius: 100%;
				box-sizing: content-box;

				&::before {
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					border: 2px solid #000;
					border-radius: 100%;
					position: absolute;
					top: 9px;
					left: 9px;
					box-sizing: content-box;
				}

				&::after {
					content: "";
					display: block;
					width: 2px;
					height: 8px;
					background: #000;
					border-radius: 6px;
					position: absolute;
					top: 19px;
					left: 22px;
					transform: rotate(-45deg);
					box-sizing: content-box;
				}
			}

			.flex-control-thumbs {
				overflow: hidden;
				zoom: 1;
				margin: 0;
				padding: 0;
				max-width: 94%;
				margin: 0;
				transform: translate(-50%, 0);
				margin-block-start: -32px;
				background: transparent;
				padding: 0;
				width: 100%;
				flex-wrap: wrap;
				display: inline-flex;
				justify-content: center;
				position: relative;
				left: 50%;
				li {
					width: 60px;
					height: 65px;
					margin: 0;
					padding-block-start: 0px;
					padding-block-end: 0px;
					background: var(--global-palette9);
					display: inline-flex;
					list-style: none;
					&:first-child {
						width: 65px;
						padding-inline-start: 5px;
					}
					&:nth-child(9) {
						width: 65px;
						padding-inline-start: 5px;
					}
					img {
						cursor: pointer;
						opacity: 0.5;
						margin: 0;
						border-top: 5px solid var(--global-palette9);
						border-bottom: 0px solid var(--global-palette9);
						border-right: 5px solid var(--global-palette9);
						border-left: 0;

						&.flex-active,
						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
		div.summary {
			margin-block-end: 2em;
			margin-inline-start: 3em;
			p {
				margin: 0.3em 0;
			}
		}
		.button.single_add_to_cart_button {
			text-align: center;
		}
		.product_meta {
			margin: 1.2em 0;
			padding: 1.2em 0;
			border-top: 1px solid var(--global-gray-400);
		}		
		.product_meta > span {
			padding: 5px 0;
			display: block;
		}
		div.social {
			text-align: right;
			margin: 0 0 1em;

			span {
				margin: 0 0 0 2px;

				span {
					margin: 0;
				}

				.stButton .chicklets {
					padding-inline-start: 16px;
					width: 0;
				}
			}

			iframe {
				float: left;
				margin-block-start: 3px;
			}
		}
		.woocommerce-tabs {

			ul.tabs {
				list-style: none;				
				margin: 0 0 1.618em;
				overflow: hidden;
				position: relative;

				li {
					border: 1px solid var(--global-gray-400);
					background-color: var(--global-palette8);
					display: inline-block;
					position: relative;
					z-index: 0;
					margin-inline-end: 10px;
					padding: 0;
					border-radius: 5px;
					a {
						display: inline-block;
						padding: 13px 30px;
						color: var(--global-palette5);
						text-decoration: none;
						&:hover {
							text-decoration: none;
							color: var(--global-palette1);
						}
					}

					&.active {
						background: var(--global-palette9);
						z-index: 2;
						border-color: var(--global-palette9);

						a {
							color: var(--global-palette1);
							text-shadow: inherit;
							background: var(--global-palette2);
							border-radius: 5px;
						}
					}

				}

				&::before {
					position: absolute;
					content: " ";
					width: 100%;
					bottom: 0;
					left: 0;
					border-bottom: 1px solid var(--global-gray-400);
					z-index: 1;
				}
			}

			.panel {
				margin: 0 0 1em;
				padding: 0;
			}
		}
		p.cart {
			margin-block-end: 2em;			
			@include clearfix();
		}

		form.cart {
			margin-block-end: 2em;
			border-block-start: 1px solid var(--global-gray-400);
			padding-block-start: 2em;


			@include clearfix();

			div.quantity {
				float: left;
				margin: 0 15px 0 0;
			}

			table {
				border-width: 0 0 1px;

				td {
					padding-inline-start: 0;
				}

				div.quantity {
					margin: 0;
				}

				small.stock {
					display: block;
					float: none;
				}
			}

			.variations {
				margin-block-end: 1em;
				border: 0;
				width: 100%;
				td,
				th {
					border: 0;
					vertical-align: top;
					line-height: 2.2em;
				}

				label {
					display: inline-block;
					margin: 0;
					font-weight: 400;
					width: 100%;
					clear: both;
					margin: 9px 0 0;
					text-align: left;
					vertical-align: middle;
				}

				select {
					border: 1px solid var(--global-gray-400);
					border-radius: 0;
					display: inline-block;
					font-size: inherit;
					max-width: 100%;
					min-width: 75%;
					margin: 6px 0;
					padding: 8px;
					border-radius: 5px;
				}

				td.label {
					padding-inline-end: 1em;
				}
			}

			.woocommerce-variation-description p {
				margin-block-end: 1em;
			}

			.reset_variations {
				visibility: hidden;
				font-size: 0.83em;
				display: block;
			}
			.woocommerce-variation-add-to-cart {
				margin-block-start: 0.5em;
				&:after {
					display: table;
					content: '';
					clear: both;
				}
			}

			.wc-no-matching-variations {
				display: none;
			}

			.button {
				vertical-align: middle;
				float: left;
			}

			.group_table {
				tr {
					display: flex;
					width: 100%;
					align-items: center;
				  }

				td.woocommerce-grouped-product-list-item__label {
					padding-inline-end: 1em;
					padding-inline-start: 1em;
					vertical-align:middle;
				}
				.woocommerce-grouped-product-list-item__price {
					padding-block-start: 0.4em;
				}
				td {
					flex: 1;
					padding: .5em .5em .5em 0;					
				}

				td:first-child {
					width: auto;
					text-align: center;
					min-width: 128px;
				}

				.wc-grouped-product-add-to-cart-checkbox {
					display: inline-block;
					width: auto;
					margin: 0 auto;
					transform: scale(1.5, 1.5);
				}
			}
		}
	}
	section.products > h2 {
		margin-block-end: 0.5em;
	}
	span.onsale {
		min-height: 0;
		min-width: 40px;
		padding: .35em .4em;
		position: absolute;
		text-align: center;
		line-height: 1em;
		top: .4rem;
		left: .4rem;
		margin: 0;
		border-radius: 0;
		background-color: #00a046;
		color: var(--global-palette9);
		border-radius: 50px;
		font-size: 12px;
		z-index: 9;
	}
   	&.product-tab-style-center div.product .woocommerce-tabs ul.tabs {
		text-align: center;
		padding: 0;
		li {
			border: 0;
			border-bottom: 3px solid transparent;
			margin: 0 0.6em;
			background: transparent;
			a {
				padding-inline-start: 0;
				padding-inline-end: 0;
			}
		}
		li.active {
			border-bottom: 3px solid var(--global-palette-highlight);
		}
   	}
	&.product-variation-style-vertical {
		div.product form.cart .variations td.label {
			padding: 0;
		}
		div.product form.cart .variations tr, div.product form.cart table td {
			display: block;
		}
		div.product form.cart .variations select {
			min-width: 240px;
			margin: 0;
		}
	}
}
.thebase-cart-button-large.woocommerce {
	div.product{
		.button.single_add_to_cart_button {
			height: 60px;
			font-size: 125%;
			width: 70%;
		}
		form.cart div.quantity {
			width: 30%;
			margin: 0;
			padding:0;
			padding-inline-end: 0.5em;
			.qty {
				width: 100%;
				height: 60px;
			}
		}
		form.cart div.quantity.spinners-added {
			width: 35%;
			display: flex;
			padding: 0;
			margin: 0 2% 0 0;
			.qty {
				width: 0;
				flex-grow: 1;
				height: 58px;
			}
			~ .button.single_add_to_cart_button {
				width: 63%;
			}
		}
	}
}
.thebase-cart-button-medium-large.woocommerce {
	div.product{
		.button.single_add_to_cart_button {
			height: 50px;
			font-size: 125%;
			width: 70%;
		}
		form.cart div.quantity {
			width: 30%;
			margin: 0;
			padding: 0 0.5em 0 0;
			.qty {
				width: 100%;
				height: 50px;
			}
		}
		form.cart div.quantity.spinners-added {
			width: 35%;
			display: flex;
			padding: 0;
			margin: 0 2% 0 0;
			.qty {
				width: 0;
				flex-grow: 1;
				height: 48px;
			}
			~ .button.single_add_to_cart_button {
				width: 63%;
			}
		}
	}
}
@media screen and (min-width: $landscape-phone-plus-query) {
	.thebase-cart-button-large.woocommerce {
		div.product{
			.button.single_add_to_cart_button {
				width: 85%;
			}
			form.cart div.quantity {
				width: 15%;
			}
		}
	}
	.thebase-cart-button-large.woocommerce div.product form.cart div.quantity.spinners-added {
		width: 25%;
	}
	.thebase-cart-button-large.woocommerce div.product form.cart div.quantity.spinners-added ~  .button.single_add_to_cart_button {
		width: 73%;
	}
	.thebase-cart-button-medium-large.woocommerce {
		div.product{
			.button.single_add_to_cart_button {
				width: 65%;
			}
			form.cart div.quantity {
				width: 15%;
			}
		}
	}
	.thebase-cart-button-medium-large.woocommerce div.product form.cart div.quantity.spinners-added {
		width: 25%;
	}
}
.single-product .thebase-breadcrumbs {
    margin-block-start: -1rem;
}
.single-product.content-vertical-padding-hide .thebase-breadcrumbs, .single-product.content-vertical-padding-block-end .thebase-breadcrumbs {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}
.content-style-unboxed .entry-content-wrap.product:not(.loop-entry) {
    padding: 0;
}
.content-style-unboxed .content-bg.loop-entry .content-bg:not(.loop-entry) {
    background: var(--global-palette9);
}
.woocommerce div.product div.thebase-product-image-wrap.images .images {
    width:auto;
    float:none;
}
.woocommerce div.product div.images .woocommerce-product-gallery__wrapper:after {
    clear: both;
    display: table;
    content: '';
}
.woocommerce div.product div.images .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:nth-child(n+2) {
	display: inline-block;
	height: 65px;
	width: 33%;
	margin-block-start: -31px;
	img {
		height: 65px;		
		margin-inline-end: auto;
		margin-inline-start: auto;
	}
}
.woocommerce div.product div.images .flex-viewport .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:nth-child(n+2) {
	margin-block-start: 0;
	height: auto;
	width: auto;
	img {
		height: auto;
	}
}
.woocommerce div.product form.cart .woocommerce-grouped-product-list .woocommerce-grouped-product-list-item .quantity.spinners-added {
    width: auto;
}
.woocommerce div.product form.cart .woocommerce-grouped-product-list .woocommerce-grouped-product-list-item div.quantity.spinners-added .qty {
    width: 1.8em;
}

.woocommerce.base-cart-button-normal div.product div.summary .added_to_cart {
	margin-top: 0;
	margin-left: 0.5em;
}
.thebase-sticky-add-to-cart-action-wrap .single_add_to_cart_button {
    height: 42px;
    line-height: 42px;
    padding: 0em 1em;
}
.woocommerce .thebase-sticky-add-to-cart-action-wrap a.added_to_cart {
	margin:0 0 0 0.5em;
}
.woocommerce div.product div.summary .added_to_cart:hover, .woocommerce .thebase-sticky-add-to-cart-action-wrap a.added_to_cart:hover {
	background: var(--global-palette9);
}
.thebase-cart-button-medium-large.woocommerce div.product div.summary .added_to_cart {
	height: 50px;
	line-height: 50px;
	font-size: 125%;
	width: 100%;
}
.thebase-cart-button-large.woocommerce div.product div.summary .added_to_cart {
	height: 60px;
	line-height: 60px;
	font-size: 125%;
	width: 100%;
}

// Spinners.
.quantity.spinners-added {
	.qty::-webkit-inner-spin-button, 
	.qty::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
		margin: 0; 
	}
	border: 1px solid var(--global-gray-400);
	background: var(--global-palette9);
    border-radius: 5px;
	transition: all 0.2s ease;
	&:hover {
		border-color: var(--global-gray-400);
	}
	input.minus, input.plus {
		background: transparent;
		color: var(--global-palette5);
		box-shadow:none;
		padding: 0 0.8em;
		line-height: 40px;
		font-size: 20px;
		border:0;
		&:hover {
			background: transparent;
			box-shadow:none;
			color: var(--global-palette5);
		}
	}
}
.quantity.hidden {
    display: none !important;
}
.woocommerce .quantity.spinners-added .qty {
    width: 3em;
    text-align: center;
    border:0;
    padding: 0;
    box-shadow: none;
	height: 42px;
	-moz-appearance: textfield;
}
// thumb overflow.
@media screen and (max-width: $tablet-minus-query) {
	.woocommerce div.product div.images .flex-control-thumbs li:nth-child(7) {
		width: 65px;
		padding-inline-start: 5px;
	}
	.woocommerce div.product div.images .flex-control-thumbs li:nth-child(9) {
		width: 60px;
		padding-inline-start: 0px;
	}
}
@media screen and (max-width: $tablet-plus-query) {
	.woocommerce div.product div.images .flex-control-thumbs li:nth-child(6) {
		width: 65px;
		padding-inline-start: 5px;
	}
	.woocommerce div.product div.images .flex-control-thumbs li:nth-child(7) {
		width: 60px;
		padding-left: 0px;
	}
	.woocommerce div.product form.cart .variations select {
		min-width: 100%;
	}
}